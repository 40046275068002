import React,{useEffect,useRef,useState} from 'react'
import { Link} from 'react-router-dom'
import { servicios,serviciotextesp,conoce } from '../Datos/Datos';
import { serviciosing,serviciotexting,conoceing } from '../Datos/Datosing';
import { serviciosfra,serviciotextfra,conocefra } from '../Datos/Datosfra';
import './Inicio.css'
import libera from './liberatusonr.webp'
import liberaing from './liberatusonr-ing.webp'
import liberafra from './liberatusonr-fra.webp'
import Quote from './WEB-06.webp'
import Contact from '../Components/Contact';
import video from '../Tecnologías/Instalaciones.mov' 
import $ from 'jquery'; 
import klintalks from './klintalks.png'

function Inicio({idioma,setdetalle}) {

  const [descripcion, setdescripcion] = useState(idioma==="esp" ? servicios : idioma==="ing" ? serviciosing : serviciosfra)
  const [texto, settexto] = useState(idioma==="esp" ? serviciotextesp : idioma==="ing" ? serviciotexting : serviciotextfra)
  const [know,setknow] = useState(idioma==="esp" ? conoce : idioma==="ing" ? conoceing : conocefra)

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
    ref.current.scrollLeft += scrollOffset;
  };
  


 const detalles = (id) => {
    setdetalle(id)
 } 

  $('body').on('click touchstart', function () {
    const videoElement = document.getElementById('home_video');
    if (videoElement.playing) {
        // video is already playing so do nothing
    }
    else {
        // video is not playing
        // so play video now
        videoElement.play();
    }
});

  useEffect(() => {
    
    window.scrollTo(0, 0)

    if(idioma==="esp"){
      setdescripcion(servicios);
      settexto(serviciotextesp)
      setknow(conoce)
    }
    if(idioma==="ing"){
      setdescripcion(serviciosing);
      settexto(serviciotexting)
      setknow(conoceing)

    }

    if(idioma==="fra"){
      setdescripcion(serviciosfra);
      settexto(serviciotextfra)
      setknow(conocefra)

    }
  }, [idioma])
  return (
    <main className="main" id="top">
        <section className="py-0">
        <video id="home_video" className='video-fondo' muted autoPlay="autoplay" loop="loop"  defaultMuted playsInline oncontextmenu="return false;"  preload="auto" src={video} type="video/mov">cd </video>
          <div className="container position-relative" style={{top: "25%",marginRight:"0px"}}>
            <div className="row align-items-center" style={{paddingTop: "200px"}}>
              <div className="col-md-5 col-lg-6 order-md-1 pt-8 align-right">
                <div style={{display:"inline-flex",width:"100%"}}>
                <img style={{right: idioma === "fra" ? "-50": null}} className="img-fluid me-4 me-md-3 me-lg-4 liberatu" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra} width="100%" alt="" /> 
              </div>
              <br/>
                <a style={{background:"#22649A",color:"white",marginTop:"30px"}} className="btn btn-lg rounded-pill hover-top" href="/tecnologies" role="button">{know[0].titulo}</a>
              </div>
              <div className="col-md-7 col-lg-6 text-center text-md-start pt-5 pt-md-9"></div>
            </div>
          </div>
        </section>
        <section className="py-4">
          <div className="bg-holder servicesbg"></div>
          
          <div className="bg-holder dot2bg3"></div>
          
          <div>
            <div className="row justify-content-center">
              <div className="col-3 text-center">
                {/* <h2 className="fw-bold">{texto[0].titulo}</h2>
                <hr className="w-25 mx-auto text-dark" style={{height:"2px"}} /> */}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-8 col-xl-8 text-center" style={{maxWidth:"800px",color:'#22649A'}}>
                <p >{texto[0].texto.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>
              </div>
            </div>
            <div className='servicios-container-div'>
            <div className="col-3 text-center inline titulo">
            <hr className="w-25 mx-auto" style={{height:"6px",color:"white",margin: "2rem 0"}} /> 
                 <h2 className="fw-bold" style={{color:"white",textDecoration:"uppercase"}}>{texto[0].titulo}</h2>
                <hr className="w-25 mx-auto" style={{height:"6px",color:"white",margin: "2rem 0"}} /> 
              </div>
              <div className='servi-container'>
              <button id="slideBack" onClick={() => {scroll(-290)}} className="prev">&#10094;</button>
            <div className="servi justify-content-center pt-7 g-4 scoll-pane" ref={ref}>
               {descripcion.map((servicio) => (
                <div style={{flex:"auto",height:"300px"}}>
              <Link onClick={() => {detalles(servicio.id)}} to={`/servicios/${servicio.id}`}>
                <div>
              <div style={{cursor: "pointer", backgroundImage: `url(${servicio.imagen})`,backgroundSize: "cover",
              backgroundPositionX: "center",borderRadius: "50%"}} className="tarjeta">
                  </div>
                <div key={servicio.id} className="cards h-100 w-100 shadow rounded-lg p-3 p-md-2 p-lg-3 p-xl-5">
                  <div className="card-body text-center text-md-start">
                    <div className="py-3">
                      <h4 className="fw-bold card-title">{servicio.titulo}</h4>
                      <p className="card-text">{servicio.sinopsis}</p>
                      <button class="btn btn-lg btn-outline-primary rounded-pill" type="submit">Ver más</button>
                    </div>
                  </div>
                </div>
                <div className="titulo-prev">
                <h2>{servicio.titulo}</h2>
                </div>
                </div>
                </Link>
              </div>
              ))}
            </div>
            <button id="slide" onClick={() => {scroll(290)}} className="next">&#10095;</button>
            </div>
            <div className="col-3 text-center inline">
              <hr className="w-100 mx-auto" style={{height:"20px",color:"white",margin: "2rem 0"}} />
              </div>
            </div>
            {/* <div className="text-center py-4"><Link to="/servicios"><button className="btn btn-lg btn-outline-primary rounded-pill" type="submit">Ver más</button></Link></div> */}
          </div>
        </section>
        {/* <section className="py-6 py-lg-8" id="about">
          <div className="container">
            <div className="row g-xl-0 align-items-center">
              <div className="col-md-6"><img className="img-fluid mb-5 mb-md-0" src="assets/images/about-1.png" width="480" alt="" /></div>
              <div className="col-md-6 text-center text-md-start">
                <h2 className="fw-bold lh-base">Liderando la <br />Odontología Nacional</h2>
                <hr className="text-dark mx-auto mx-md-0" style={{height:"2px",width:"50px"}} />
                <p className="pt-3">En Klin by GOA tenemos la más alta tecnología para asegurar que tu dentadura sea especial.</p>
                <div className="py-3"><NavLink to="/tecnologies"><button className="btn btn-lg btn-outline-primary rounded-pill" type="submit">Aprender más </button></NavLink></div>
              </div>
            </div>
          </div>
        </section> */}
      
        
       
        <section className="py-4" id="testimonial">
          <div className="container">
            <div className="bg-holder z-index-1 dotbg3"></div>
            
            <div className="bg-holder z-index-1 dot2bg4"></div>
            
            <div className="carousel slide" id="carouselExampleDark" data-bs-ride="carousel">
            <div className='quote-container'>
                  <img className="img-fluid me-4 me-md-3 me-lg-4" src={Quote} width="100%" alt="" /> 
                  </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="10000">
                  <div className="row h-100">
                    <div className="col-8">
                      <div className="card text-white"style={{background: "#85D4F0"}}>
                        <div className="card-body p-4 p-md-4 p-lg-7 sin-padding">
                          <div style={{display:"flex",flexDirection:"column"}}>
                          <h1 className="fw-bold text-right" style={{color:"#22649A"}}>FAMILIA KLIN</h1>
                          <hr className="mx-auto-klin" style={{height:"5px",width:"150px",marginRight:"0px !important"}} />
                          </div>
                          <div className="d-md-flex align-items-md-center mt-5 text-center text-md-start">
                            {/* <img className="img-fluid me-4 me-md-3 me-lg-4" src="assets/images/user-1.png" width="100" alt="" />  */}
                            {/* <div className="w-md-25 my-3">
                              <h5 className="mb-0 fw-medium text-white">Alfonso Juarez</h5>
                              <p className="fw-normal mb-0">Cliente satisfecho</p>
                            </div> */}
                            <div className="w-md-75">
                              <p className="card-talk ms-md-5">“Isabel me atendió excelente, me cambiaron toda la dentadura y no sentí nada!”</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="row h-100">
                    <div className="col-8">
                      <div className="card text-white" style={{background: "#85D4F0"}}>
                        <div className="card-body-blog p-4 p-md-4 p-lg-7">
                          <h1 className="fw-bold text-right" style={{color:"#22649A"}}>FAMILIA KLIN</h1>
                          <hr className="mx-auto-klin" style={{height:"5px",width:"150px",marginRight:"0px !important"}} />
                          <div className="d-md-flex align-items-md-center mt-5 text-center text-md-start">
                            {/* <img className="img-fluid me-4 me-md-3 me-lg-4" src="assets/images/user-2.png" width="100" alt="" /> */}
                            {/* <div className="w-md-25 my-3">
                              <h5 className="mb-0 fw-medium text-white">Patricia Jimenez</h5>
                              <p className="fw-normal mb-0">Mamá de paciente</p>
                            </div> */}
                            <div className="w-md-75">
                              <p className="card-talk ms-md-5">“Mi niño tenía molestias en la encia,lo lleve a KLIN y se le quitó el hinchazón en cuestión de minutos. Muchas gracias”</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row h-100">
                    <div className="col-8">
                      <div className="card text-white" style={{background: "#85D4F0"}}>
                        <div className="card-body p-4 p-md-4 p-lg-7">
                          <h1 className="fw-bold text-right" style={{color:"#22649A"}}>FAMILIA KLIN</h1>
                          <hr className="mx-auto-klin" style={{height:"5px",width:"150px",marginRight:"0px !important"}} />
                          <div className="d-md-flex align-items-md-center mt-5 text-center text-md-start">
                            {/* <img className="img-fluid me-4 me-md-3 me-lg-4" src="assets/images/user-3.png" width="100" alt="" /> */}
                            {/* <div className="w-md-25 my-3">
                              <h5 className="mb-0 fw-medium text-white">Juan Escutia</h5>
                              <p className="fw-normal mb-0">Paciente</p>
                            </div> */}
                            <div className="w-md-75">
                              <p className="card-text ms-md-5">“Tenía unas muelas picadas, me tuvieron que limpiar y rellenar las muelas, pense que se iba a notar pero se ve como un diente normal; Gracias KLIN”</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 flex-center">
                <div className="col-4 col-sm-5 text-end position-relative z-index-2"><a className="carousel-control-prev carousel-icon z-index-2" href="#carouselExampleDark" role="button" data-bs-slide="prev"><span className="carousel-control-prev-icon" aria-hidden="true"></span><span className="visually-hidden">Previous</span></a></div>
                <div className="col-auto position-relative z-index-2">
                  <ol className="carousel-indicators">
                    <li className="active" data-bs-target="#carouselExampleDark" data-bs-slide-to="0"></li>
                    <li data-bs-target="#carouselExampleDark" data-bs-slide-to="1"></li>
                    <li data-bs-target="#carouselExampleDark" data-bs-slide-to="2"></li>
                  </ol>
                </div>
                <div className="col-4 col-sm-5 position-relative z-index-2"><a className="carousel-control-next carousel-icon z-index-2" href="#carouselExampleDark" role="button" data-bs-slide="next"><span className="carousel-control-next-icon" aria-hidden="true"></span><span className="visually-hidden">Next</span></a></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="bg-holder articlebg"></div>
          
          <div className='klintalks-container'>
            <div className="bg-holder dot2ng"></div>
            
            <div className="row flex-center">
            <Link to="/klintalks">
            <div className='leer-container'>
              <h2 className='leer-text'>LEER</h2>
              <div className='plus-container'>
                  <i className='mas-simbolo fa-solid fa-plus'></i>
                  </div>
              </div>
              </Link>
              <div className="col-auto text-center">
              <img className="img-fluid me-4 me-md-3 me-lg-4 klin-talks-img"  src={klintalks} alt="" /> 
                <h2 className="fw-bold klin-talks"><span style={{color:"white"}}>KLIN</span><span style={{color:"#7DAF80"}}>talks!</span></h2>
              </div>
            </div>
            <div className="row h-100 justify-content-center">
              <div className="col-12 col-sm-9 col-md-4 mt-4 blogs">
                <div className="talks h-100">
                  <div className="card-body p-4 text-center text-md-start">
                  <div className='blog-height'>
                    <h2 className="fw-bold talk-title">La selfie perfecta</h2>
                    <p className="card-text">Cuando no estás contento con tu sonrisa, es difícil sentirte seguro. Tomarte selfies sonriendo, hablar abiertamente con otros y conocer gente nueva puede sentirse como un desafío si no quieres que otros vean tu sonrisa. El diseño de..</p>
                  </div> 
                    <span style={{fontSize:"12px",fontWeight:"800"}}>Isabel Gonzalez</span>
                    <br/>
                    <span style={{fontSize:"12px",fontFamily:"Source Sans Pro"}}>12 de Octubre del 2022</span>
                    <br/>
                  </div>
                  <img src="assets/images/article-1.png" className="talks-images" alt="" />
                </div>
              </div>
              <div className="col-12 col-sm-9 col-md-4 mt-4 blogs">
                <div className="talks h-100">
                  <div className="card-body p-4 text-center text-md-start">
                  <div className='blog-height'>
                    <h2 className="fw-bold talk-title">Tres cosas que no sabías de...</h2>
                    <p className="card-text">Cuando no estás contento con tu sonrisa, es difícil sentirte seguro. Tomarte selfies sonriendo, hablar abiertamente con otros y conocer gente nueva puede sentirse como un desafío si no quieres que otros vean tu sonrisa. El diseño de..</p>
                    </div>
                    <span style={{fontSize:"12px",fontWeight:"800"}}>Isabel Gonzalez</span>
                    <br/>
                    <span style={{fontSize:"10px",fontFamily:"Source Sans Pro"}}>12 de Octubre del 2022</span>
                    <br/>
                  </div>
                  <img src="assets/images/article-2.png" className="talks-images" alt="" />
                </div>
              </div>
            </div>
              <Contact idioma={idioma}/>
          </div>
        </section>
      </main>
  )
}

export default Inicio
