import React,{useEffect,useState} from 'react'
import { doctores,equipo,turismo } from '../Datos/Datos';
import { equipofra,turismofra } from '../Datos/Datosfra';
import { equipoing,turismoing } from '../Datos/Datosing';
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'
import { Link } from 'react-router-dom'
import './Turismo.css';

function Turismo({idioma}) {
  const [texto, settexto] = useState(idioma==="esp" ? turismo[0] : idioma==="ing" ? turismoing[0] : turismofra[0])
  useEffect(() => {
    if(idioma==="esp"){
      settexto(turismo[0])
    }
    if(idioma==="ing"){
      settexto(turismoing[0])
    }

    if(idioma==="fra"){
      settexto(turismofra[0])
    }
  }, [idioma])
console.log(texto)
  return (
    <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="tu-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
            <div className="row align-items-center contenido-titulo">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <div style={{display:"inline-flex"}}><h1 className="mb-4 display-3 fw-bold blanco">TURISMO</h1></div>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-2">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
                <p>{texto.descripcion.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p> 
              </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
            <div  style={{maxWidth:"1000px",marginLeft:"auto",marginRight:"auto",padding: "20px",marginTop:"0px",paddingTop:"0px"}}>
            <div className="row col-sm-10 col-xl-8 text-center" style={{marginLeft:"auto",marginRight:"auto"}}>
              <p>{texto.ofertas.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>     
              <a style={{textAlign: "center"}} target="_blank" href="https://www.airbnb.com/rooms/2537521?guests=1&adults=1&s=67&unique_share_id=1a698875-2f17-4d7a-90e1-16ed73d02c12"><i class="fa-brands fa-5x fa-airbnb"></i></a>
              <p style={{marginTop:"1rem"}}>{texto.quedarse.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p> </div>
            </div>
            </div>
            <Contact idioma={idioma}/>

          </div>
          </section>
          </main>
  )
}



export default Turismo