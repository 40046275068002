import React, { useState,useEffect } from 'react'
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import './Tecnologies.css'
import {tecnologias} from '../Datos/Datos';
import {tecnologiasfra} from '../Datos/Datosfra';
import {tecnologiasing} from '../Datos/Datosing';
import klin from './klinbygo.png'
import fondo from './fondo-technology.PNG'
import video from './NUESTRO-ESPACIO.mov'
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'
import $ from 'jquery'; 

function Tecnologies({idioma}) {
  const [desc, setdesc] = useState(idioma==="esp" ? tecnologias: idioma==="ing" ? tecnologiasing : tecnologiasfra)
  const parrafo = desc[0].tecnolog;
  const [id, setid] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
    if(idioma==="esp"){
      setdesc(tecnologias)
    }
    if(idioma==="ing"){
      setdesc(tecnologiasing)
    }

    if(idioma==="fra"){
      setdesc(tecnologiasfra)
    }
  }, [idioma])

  const click = (id) => {
     if(desc !== null)
 setid(id)
  }

  $('body').on('click touchstart', function () {
    const videoElement = document.getElementById('tec_video');
    if (videoElement.playing) {
        // video is already playing so do nothing
    }
    else {
        // video is not playing
        // so play video now
        videoElement.play();
    }
});

  return (
  <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="tt1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
            <div className="row align-items-center contenido-titulo">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <h1 className="mb-4 display-3 fw-bold blanco">{desc[0].titulo}</h1>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-2">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
              <div className="col-3 text-center">
              </div>
              <div style={{display:'inline-flex',width:'100%'}}>
              <div style={{width:"100%",position:"relative",marginRight:"auto",marginLeft:"auto"}}>
              <video id="tec_video" style={{width: "100%"}} muted autoPlay="autoplay" loop="loop"  defaultMuted playsInline oncontextmenu="return false;"  preload="auto" src={video} type="video/mov"></video>

          {/* <Player
      playsInline
      poster={fondo}
      src={video}
    /> */}
    </div>
    </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
            </div>
            <div style={{background:"white"}}>
            <div style={{maxWidth: "1000px",margin:"0 auto auto"}} className="row justify-content-center white">
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-3 text-center inline titulo">
                <hr className="w-25 mx-auto" style={{height:"6px",color:"#22649A",margin: "1rem 0"}} /> 
                 <h3 className="fw-bold" style={{color:"#22649A"}}>{desc[0].subtitulo}</h3>
                <hr className="w-25 mx-auto" style={{height:"6px",color:"#22649A",margin: "1rem 0"}} />
                </div>
              <div className="col-6 text-center">
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
              <p >{desc[0].descripcion.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>
              </div>
            </div>
            <img className="libera" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div>
            <div className='col-md-120' style={{marginBottom:"0px",paddingBottom:"40px"}}>
  {/* <div className="menu-prod">
<div className="menu-ascend hover product-menu">
  <a className="first border-right space" style={{color: id===0 ? "#22b5c4":"#458FF6",cursor:"pointer"}} onClick={() => click(0)}><span className="hover">Diseño de Sonrisa</span></a> 
  <a className="border-right space" style={{color: id===1 ? "#22b5c4":"#458FF6",cursor:"pointer"}} onClick={() => click(1)}><span className="hover">AirFlow Profilaxis Máster</span></a> 
  <a className="border-right space" style={{color: id===2 ? "#22b5c4":"#458FF6",cursor:"pointer"}} onClick={() => click(2)}><span className="hover">Escaner iTero</span></a> 
  <a className="last space" style={{color: id===3 ? "#22b5c4":"#458FF6",cursor:"pointer"}} onClick={() => click(3)}><span className="hover">Waterlasse MDX</span></a> 
</div>
</div> */}
{/* <div style={{position:"relative"}}><div className='horizontal-line'></div></div>
<div style={{position:"relative"}}><div className='vertical-line'></div></div>
<div style={{position:"relative"}}><img className="d-inline-block me-3 imagen-fondo" src={klin} width="70" alt="" /></div> */}
{parrafo.map((text) => (
<div className='tecnologia-text'>
<div class="team-member" style={{position:"absolute",marginLeft:"-140px"}}>
          <div class="team-img">
              <img src={text.imagen} alt="team member" class="img-responsive"/>
          </div>
      </div>
      <div className='tech-text-container'>
<h2 className='tecnologia-desc-title fw-bold' style={{paddingLeft:"18%",color:"#22649A"}}>{parrafo ? text.titulo:""}</h2>
    <h4 className='tecnologia-desc' style={{color:"white"}}>{parrafo ? text.texto.split('\n').map(line => <h4 style={{marginBottom: "3px",color:"white",fontFamily:"Source Sans Pro"}}>{line}</h4>): ""}</h4>
    </div>
  </div>
))}
</div>
</div>
            <Contact idioma={idioma}/>

          </div>
          </section>
          </main>
  )
}

export default Tecnologies