
import React,{useEffect,useState} from 'react'
import './Header.css';
import {Link, NavLink } from 'react-router-dom';
import klin from './klinbygo.png'
import klintitle from './klinbygo-title.png'
import {header} from '../../Datos/Datos';
import {headerfra} from '../../Datos/Datosfra';
import {headering} from '../../Datos/Datosing';

function Header({idioma,setidioma}) {

  const [headers, setheaders] = useState(idioma==="esp" ? header[0]: idioma==="ing" ? headering[0] : headerfra[0])

  useEffect(() => {
    if(idioma==="esp"){
      setheaders(header[0])
    }
    if(idioma==="ing"){
      setheaders(headering[0])
    }

    if(idioma==="fra"){
      setheaders(headerfra[0])
    }
  }, [idioma])


  const idiomas= (e) =>{ 
    console.log(e.target.value)
    setidioma(e.target.value)
  }
  
  return (
    <nav className="navbar light-blue navbar-expand-lg navbar-light fixed-top py-3" data-navbar-on-scroll="data-navbar-on-scroll">
    <div className="container"><a className="navbar-brand d-flex align-items-center fw-bold fs-2" href="/"><div className="logo-container"><img className="d-inline-block me-3 logo" src={klin} alt="" /></div><img className="d-inline-block me-3 logo-title" src={klintitle} alt="" /></a><button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
      <div className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
        <ul className="navbar-nav margin-auto pt-2 pt-lg-0">
        <NavLink to="/"><li className="nav-item"><a className="nav-link" aria-current="page" href="/">{headers.inicio}</a></li></NavLink>
        <NavLink to="/servicios"><li className="nav-item"><a className="nav-link" href="/servicios">{headers.servicios}</a></li></NavLink>
        <NavLink to="/team"> <li className="nav-item"><a className="nav-link" href="/team">{headers.equipo}</a></li></NavLink>
        <NavLink to="/tecnologies"> <li className="nav-item"><a className="nav-link" href="/tecnologies">{headers.tecnologias}</a></li></NavLink> 
        <NavLink to="/agenda"><li className="nav-item"><a className="nav-link" href="/agenda">{headers.cita}</a></li></NavLink> 
        <li className="nav-item">
</li>
        </ul>
               <select className="idiomas-select nav-link idioma" name="select" value={idioma} onChange={idiomas}>
        <option style={{color:"black"}} value="esp" >ESP</option>
        <option style={{color:"black"}} value="ing">ING</option>
        <option style={{color:"black"}} value="fra">FRA</option>
</select>
      </div>
    </div>
  </nav>
  )
}

export default Header

