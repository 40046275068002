const serviciosfra =[
    {id:0,
    titulo: "PROPHYLAXIE DU PROFESSIONNEL DENTAIRE",
    imagen:"assets/images/Servicios/limpieza.jpg",
    sinopsis:"Rapide, indolore et sans user davantage vos dents.",
    desplegable:"À klin by goa, nous effectuons un nettoyage dentaire avec la technologie air-flow prophylaxis master, un dispositif fabriqué en suisse. Il offre une solution unique pour la prévention de la carie et des maladies parodontales.",
    descripcion:"Grâce à l’expulsion de nanoparticules d’érythritol et à des pointes spécialisées, il est possible d’accéder à des espaces qui semblaient jusqu’maintenant impossibles à atteindre; un nettoyage à haute efficacité en peu de temps. Toutefois, il est important de noter que les avantages du flux d’air ne se limitent pas à son efficacité, mais au confort des patients, par rapport aux autres méthodes conventionnelles, ce qui réduit de manière significa tive le temps de tra v ail et la sensa tion douloureuse que ce type d’intervention peut provoquer. Simplement une nouvelle façon de prévenir et de contrôler l’hygiène buccodentaire!",
    galeria:[{original:"/assets/images/Galeria-servicios/limpieza-1.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-1.webp"},{original:"/assets/images/Galeria-servicios/limpieza-2.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-2.webp"},{original:"/assets/images/Galeria-servicios/limpieza-3.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-3.webp"}]

},
    {
    id:1,
    titulo: "MAINTENANCE",
    sinopsis:"Annuelle ou semestrielle ",
    imagen:"assets/images/Servicios/mantenimiento.jpg",
    desplegable:" À KLIN by GOA, nous avons une approche préventive et conservatrice. ",
    descripcion:"Nous savons que chaque changement de restauration implique, dans la plupart des cas, une plus grande usure de la structure dentaire et c’est pourquoi nous créons un protocole d’entretien annuel qui vise à préserver les restaurations à longue durée de vie dans la bouche. Il faut sceller de nouveau l’interface entre la restauration et la dent afin d’éviter un remplacement de la même dent à court ou moyen terme. Notre protocole de maintenance comprend:"
    + "\\n▪ Contrôle radiographique de toutes les restaurations et dents présentes dans la bouche "
    + "\\n▪ Analyse clinique complète "
    + "\\n▪ nettoyage dentaire professionnel avec système 'air-flow prophylaxis master'"
    + "\\n▪ Maintien de toutes les restaurations présentes dans la bouche, en évitant le Remplacement à moyen terme"
    + "\\n▪ Application topique de fluorure et application de traitements de rappel"
    + "\\n▪ Réalisation d’une garde occlusale nocturne ",
    galeria:[{original:"/assets/images/Galeria-servicios/mantenimiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-1.webp",},{original:"/assets/images/Galeria-servicios/mantenimiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-2.webp"},{original:"/assets/images/Galeria-servicios/mantenimiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-3.webp"}],
},
    {
    id:2,
    titulo: "BLANCHIMENT AU BUREAU",
    sinopsis:"Blanchit jusqu'à 8 teintes en 45 minutes.",
    imagen:"assets/images/Servicios/BLANQUEAMIENTO.jpg",
    desplegable:"Philips Zoom Whitespeed lamp est la marque numéro 1 de blanchiment demandée par les patients aux états-unis et est cliniquement éprouvée pour blanchir les dents jusqu’à huit nuances en 45 minutes offrant des résultats et une efficacité cliniquement supérieure aux autres solutions de blanchiment professionnelles. C’est pourquoi à klin by goa nous avons cette technologie pour vous offrir un meilleur service chaque jour. Whitespeed a obtenu un avantage de 50 % sur les méthodes classiques qui n’utilisent pas la lumière immédiatement après le traitement. ",
    descripcion:"Le blanchiment des dents philips zoom est habituellement indiqué chez les patients qui ne sont pas d’accord avec la couleur de leurs dents. De plus, ses principales indications sont les cas de décoloration des dents, causée par le vieillissement, une fluorose dentaire légère, une dentinogenèse imparfaite ou des taches/pigments dus à des facteurs externes tels que le café, le vin et le cigare. Avant de commencer le blanchiment des dents, certaines considérations préliminaires doivent être prises en compte afin de pouvoir effectuer un bon traitement. Il faut tout d’abord faire un historique clinique détaillé, avec la raison de la consultation, les considérations médicales et une évaluation des habitudes de chaque patient. Ensuite, des examens oraux et complémentaires sont effectués. Il est également important de prendre"
    + "Des enregistrements, comme la colorimétrie pour spécifier la couleur a v ant le traitement. Enfin, avant de réaliser le plan de traitement, un diagnostic de certitude et l’établissement d’un pronostic est fondamental. Il est très important de souligner que le succès du blanchiment dentaire et sa longévité dans toute intervention dépend des habitudes du patient.",
    galeria:[{original:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",},{original:"/assets/images/Galeria-servicios/blanqueamiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-2.webp"},{original:"/assets/images/Galeria-servicios/blanqueamiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-3.webp"}],
},
    {
    id:3,
    titulo: "INVISALIGN",
    sinopsis:"Ortodoncia invisible rápida, cómoda y más higiénica",
    imagen:"assets/images/Servicios/INVISALIGN.jpg",
    desplegable:"C'est la solution parfaite et confortable pour corriger les problèmes de sourire, permettant le bon alignement des dents en moins de temps que l'orthodontie traditionnelle.",
    descripcion:"Consiste à réaliser des attelles en plastique transparent et faciles à mettre en place."
    +"Son placement est très simple, il permet donc au patient de les placer et de les retirer sans la supervision de votre dentiste."
    +"Le kit de gouttières est fourni au patient dès le début du traitement, devant remplacer l'attelle en cours d'utilisation toutes les deux ou trois semaines."
    +"Comme ils sont amovibles, vous pouvez les retirer pour mordre et mâcher sans souci, sans craindre de les casser ou de les détacher de vos dents pendant que vous mangez."
    +"De plus, ils sont imperceptibles et coupés au niveau de la gencive, donc personne ne les remarquera de loin ou de près."
    +"Une solution efficace pour obtenir le sourire de vos rêves dans les plus brefs délais."
    ,galeria:[{original:"/assets/images/Galeria-servicios/invisalign-1.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-1.webp",},{original:"/assets/images/Galeria-servicios/invisalign-2.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-2.webp"},{original:"/assets/images/Galeria-servicios/invisalign-3.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-3.webp"}],
},
    {
    id:4,
    titulo: "KLIN KIDS",
    sinopsis:"Aller chez le dentiste, c'est amusant !",
    imagen:"assets/images/Servicios/KLINKIDS.jpg",
    desplegable:"Notre objectif principal est de donner aux enfants des soins bucco-dentaires appropriés, basés sur deux principes de base : la guérison et la prévention.",
    descripcion:"Nous sommes experts en gestion du comportement, en prévention dentaire mini-invasive et en orthodontie préventive."
    +"Chez KLIN Kids, nous sommes prêts à fournir des soins aux enfants ayant des expériences négatives antérieures, des premières visites ou des capacités spéciales, en utilisant des techniques et une technologie de haut niveau, causant le moins de douleur possible, réduisant l'inconfort et le stress qu'une visite chez le dentiste peut causer vos petits."
    +"Nous pensons que la prévention est la clé, c'est pourquoi nous conseillons et soutenons également les parents pour que la santé et le sourire commencent à la maison."
    +"Ensemble, nous formons une équipe!",
    galeria:[{original:"/assets/images/Galeria-servicios/kids-1.webp",thumbnail:"/assets/images/Galeria-servicios/kids-1.webp",},{original:"/assets/images/Galeria-servicios/kids-2.webp",thumbnail:"/assets/images/Galeria-servicios/kids-2.webp"},{original:"/assets/images/Galeria-servicios/kids-3.webp",thumbnail:"/assets/images/Galeria-servicios/kids-3.webp"}],
    },
    {
    id:5,
    titulo: "SALIVA CHECK TEST",
    sinopsis:"Test clinique pour évaluer la qualité de la salive.",
    imagen:"assets/images/Servicios/testsaliva.jpg",
    desplegable:"Saliva check buffer vérifie l’hydratation, la consistance, le ph de la salive au repos, le débit de salive stimulé, le ph de la salive stimulée et la capacité d’amortissement. Ces six tests sont réalisés en moins de 10 minutes au total par patient. ",
    descripcion:"Saliva check buffer est très utile pour identifier les facteurs contributifs, tels que le stress, le tabac, les maladies, une pathologie des glandes salivaires, l’insuffisance rénale chronique, un déséquilibre hormonal dû à la ménopause et les effets secondaires des médicaments. Les résultats peuvent être expliqués au patient pendant la conversation sur la prévention et le traitement."
    ,galeria:[{original:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",},{original:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp"},{original:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp"}],
},
         {
    id:6,
    titulo: "Technologie laser",
    sinopsis:"Waterlasse MDX® est notre arme secrète contre la douleur.",
    imagen:"assets/images/Servicios/LÁSER.jpg",
    desplegable:"À klin by goa, nous croyons qu’un rendez-vous chez le dentiste ne doit pas être une expérience négative et nous utilisons la technologie laser pour réduire le niveau de douleur chez nos patients.",
    descripcion:"Nous voulons que nos patients sachent qu’ils sont entre de bonnes mains et que nous prenons soin de leur confort et de leur bien-être."
    +"\\n"
    +"\\nComment on fait ça ?"
    +"\\nAvec un équipement comme le waterlase mdx, qui garantit qu’une expérience dentaire est minimalement invasive, précise et exceptionnellement agréable. Ces instruments sont utilisés par des dentistes renommés du monde entier et, bien sûr, à klin by goa. C’est une garantie pour nos patients, parce qu’ils offrent des avantages importants:"
    +"\\n"
    +"\\nil est parfait pour les traitements des enfants, car il est convivial et calme. Cela gardera nos enfants calmes et évitera les expériences traumatisantes liées aux visites dentaires."
    +"\\nRéduit le temps d’attente des patients. De cette façon, vous pouvez mieux gérer vos activités le jour de la consultation, car le temps passé en fauteuil dentaire est considérablement réduit."
    +"\\nRéduit considérablement les niveaux de douleur. C’est très important pour les personnes souffrant d’anxiété car cela aide à garder le patient calme et réduit le stress généré par le traitement."
    +"\\n "
    +"\\n IT ELIMINATES OR REDUCES DENTAL SENSITIVITY, AND IN MANY CASES IT IS NOT EVEN NECESSARY TO USE ANESTHESIA."
    +"\\nIlprocure du confort en améliorant de façon unique les expériences dentaires... Vous voudrez certainement revenir avec nous!"
    +"\\nIlélimine ou réduit la sensibilité dentaire, et dans de nombreux cas, il n’est même pas nécessaire d’utiliser une anesthésie."
    ,    galeria:[{original:"/assets/images/Galeria-servicios/Waterlase-1.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-1.webp",},{original:"/assets/images/Galeria-servicios/Waterlase-2.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-2.webp"},{original:"/assets/images/Galeria-servicios/Waterlase-3.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-3.webp"}],
},
]

const doctoresfra =[
    {id:0,
    imagen:"assets/CV/Karina.jpg",
    nombre:"Karina López-Gazcón",
    especialidad: "Dentista",
    descripcion: "Prothèse orale et implantaire",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Karina-fra.pdf",

},
{id:0,
    imagen:"assets/CV/Guadalupe.jpg",
    nombre:"Guadalupe González",
    especialidad: "Dentista",
    descripcion: "Prothèse orale et implantaire",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Guadalupe-fra.pdf",

},
{id:0,
    imagen:"assets/CV/Rocio.jpg",
    nombre:"Rocío Vega ",
    especialidad: "Dentista",
    descripcion: "Dentisterie restauratrice avancée",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Rocio-fra.pdf",

},
{id:0,
    imagen:"assets/CV/Maria.jpg",
    nombre:"María Zamudio",
    especialidad: "Dentista",
    descripcion: "Dentisterie restauratrice avancée",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Maria-fra.pdf",

},
// {id:0,
//     imagen:"assets/CV/Ivonne.jpg",
//     nombre:"Ivonne González",
//     especialidad: "Periodoncista",
//     descripcion: "Orthodontie et orthopédie maxillaire",
//     facebook:"https://www.facebook.com/klinbygoa",
//     instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
//     twitter:"https://twitter.com",
//     cv:"Ivonne.pdf",

// },
{id:0,
    imagen:"assets/CV/Cesar.jpg",
    nombre:"César Benítez",
    especialidad: "Dentista",
    descripcion: "Ortodoncia y Ortopedia Maxilar",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Cesar-fra.pdf",

},
// {id:0,
//     imagen:"assets/CV/Victor.jpg",
//     nombre:"Victor Gallardo ",
//     especialidad: "Dentista",
//     descripcion: "Cirugía Oral y Maxilofacial",
//     facebook:"https://www.facebook.com/klinbygoa",
//     instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
//     twitter:"https://twitter.com",
//     cv:"Victor-fra.pdf",

// },
{id:0,
    imagen:"assets/CV/Lucía.jpg",
    nombre:"Lucía Márquez",
    especialidad: "Dentista",
    descripcion: "Prévention et blanchiment",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Lucía-fra.pdf",

},
{id:0,
    imagen:"assets/CV/Elizabeth.jpg",
    nombre:"Elizabeth Pastrana",
    especialidad: "Dentista",
    descripcion: "Prévention et blanchiment",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Elizabeth-fra.pdf",

},
{id:0,
    imagen:"assets/CV/Ana.jpg",
    nombre:"Ana Caren González",
    especialidad: "Dentista",
    descripcion: "Prévention et blanchiment",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Ana-fra.pdf",

},
]

const contactofra=[{
   titulo:"Des questions?",
   nombre:"Nom",
   nombredesc:"Toi nom",
   apellido:"Prénom",
   apellidodesc:"Toi prénom",
   correo:"Email",
   correodesc:"Toi email",
   telefono:"Téléphone",
   telefonodesc:"Toi téléphone",
   notas:"Raison de la nomination",
   enviar:"Envoyer",
   liberatitulo:"Libérez votre sourire!",
   estasclick:"Vous êtes à un clic du sourire de vos rêves. Envoyez-nous vos questions ou prenez rendez-vous,",
   cita:"Prendre rendez-vous",
   agendar:"Rendez-vous",
   semana:"LUNDI AU SAMEDI"
  }]

  const regresarfra =[{
    regresar:"Retournez"
}]

  const agendafra=[{
    titulo:"Libérez votre sourire!",
    subtitulo:"Vous êtes à un clic du sourire de vos rêves. Envoyez-nous vos questions ou prenez rendez-vous",
 nombre:"Nom",
 nombredesc:"Toi nom..",
 apellido:"Toi prénom",
 apellidodesc:"Your last name..",
 correo:"Email",
 correodesc:"Toi email..",
 telefono:"Téléphone",
 telefonodesc:"Toi téléphone..",
 fecha:"Date",
 fechadesc:"Date de rendez-vous..",
 horario:"Hours range",
 horariodes:"Choisissez une plage d'heures pendant laquelle vous souhaitez que votre requête soit effectuée. Tenez compte du fait que la durée minimale de la consultation est de 1h30.",
 notas:"Raison de la nomination",
 enviar:"Envoyer",
 agendar:"Rendez-vous",
 selecciona:"Sélectionnez une Option",
 detalle:"*Choisissez une plage d'heures pendant laquelle vous souhaitez que votre requête soit effectuée. Tenez compte du fait que la durée minimale de la consultation est de 1h30."
 +"Votre rendez-vous sera confirmé via WhatsApp avec l'heure exacte du service."
}]

  const equipofra=[{
    titulo:"Équipe",
    texto:"<strong>Klin</strong> by goa est composé d’un groupe de dentistes, hautement qualifiés et déterminés à vous offrir un service de qualité avec des traitements préventifs et esthétiques, et des solutions de rechange à divers maux grâce à une technologie de la plus haute qualité qui garantit une satisfaction totale." 
    +"\n À <strong>KLIN</strong> BY GOA, nous prenons soin d'écouter nos patients et que chaque visite soit spéciale."
    +"Vous aurez l'assurance d'être entre les meilleures mains.",
}]

const tecnologiasfra=[{
    titulo:"Technologies",
    subtitulo:"Découvrez notre espace!",
    descripcion:"Tous nos traitements et services sont effectués dans des installations confortables avec les plus hauts standards de qualité et de confort."
    +"\n De plus, nous cherchons à créer un environnement qui provoque l'harmonie entre le corps et l'esprit, car nous savons que le meilleur sourire vient de l'intérieur.",
    nombre:"Diseño de Sonrisa",
    tecnolog:[
    {titulo:"PHILLIPS ZOOM WhiteSpeed®️",imagen:"assets/images/Tecnologias/philips.webp",texto:"La lampe Philips Zoom WhiteSpeed ®️ est la marque de blanchiment numéro 1 demandée par les patients du monde entier. Il est cliniquement prouvé qu'il éclaircit les dents jusqu'à huit teintes en 45 minutes, offrant des résultats et une efficacité cliniquement supérieurs à d'autres alternatives de blanchiment professionnelles."},
    {titulo:"AirFlow Profilaxis Máster",imagen:"assets/images/Tecnologias/AIRFLOW-MÁQUINA.webp",texto:"La lampe Philips Zoom WhiteSpeed ®️ est la marque de blanchiment numéro 1 demandée par les patients du monde entier. Il est cliniquement prouvé qu'il éclaircit les dents jusqu'à huit teintes en 45 minutes, offrant des résultats et une efficacité cliniquement supérieurs à d'autres alternatives de blanchiment professionnelles."},
    {titulo:"Escaner iTero",imagen:"assets/images/Tecnologias/itero.webp",texto:"Avec le scanner intra-oral iTero, il est plus facile d'obtenir le sourire parfait. Le moule de la bouche est fabriqué sans qu'il soit nécessaire d'utiliser un quelconque type de pâte. En quelques minutes, il sera possible de visualiser toute la bouche du patient sur l'écran, accélérant les diagnostics et permettant de déterminer efficacement le traitement le plus approprié."},
    {titulo:"Waterlasse MDX",imagen:"assets/images/Tecnologias/waterlase.webp",texto:"Nous utilisons une technologie laser de pointe pour réduire considérablement le temps et la douleur de nos patients, garantissant ainsi des traitements peu invasifs, précis et exceptionnellement agréables."},
    ]
}]

const conocefra=[{
    titulo:"SAVOIR PLUS"
}]

const turismofra=[{
    descripcion:"Le tourisme dentaire est devenu une nouvelle façon pour les touristes étrangers de répondre à leurs besoins dentaires. L'un des plus grands avantages et des principales raisons pour lesquelles les gens choisissent le tourisme dentaire"
    +"au Mexique c'est à cause de leurs prix généralement beaucoup plus accessibles que dans leur pays de résidence."
    +"\n\n Chez Klin by GOA, nous nous engageons pour le bien-être des patients en leur offrant les meilleurs soins, ainsi qu'un soutien pendant cette expérience de vacances dentaires. Notre objectif principal est que vous effectuiez votre traitement dentaire pendant que vous appréciez vraiment"
    +"tout ce que la Ville a à offrir. Une fois que vous aurez décidé de vous accompagner avec nous, notre service voyages vous contactera pour confirmer votre premier rendez-vous et"
    +"planifier ensemble votre visite à Mexique.",
    ofertas:"Obtenez les meilleures offres d'hébergement sur Airbnb lorsque vous réservez avec Klin",
    quedarse:"Vous venez au CDMX et vous avez besoin d'un logement ?" 
    +"\n\n Il existe des centaines d'options pour tous les budgets. Les coordonnateurs du service à la clientèle de KLIN by GOA se feront un plaisir de vous guider dans votre recherche du meilleur endroit pour vous."
}]

const headerfra=[{
    inicio:"Accueil",
    servicios:"Service",
    equipo:"Équipe",
    tecnologias:"Technologies",
    cita:"Rendez-vous"
}]

const footerfra=[{
    enklin: "Chez KLIN by GOA, nous fournissons le meilleur service dentaire",
    inicio:"Accueil",
    servicio:"Services",
    equipo:"Équipe",
    tecnologia:"Technologies",
    agenda:"Rendez-vous",
    klin:"KLIN Talks",
    Bella:"Embellissement et conception de sourire",
    info:"Información"
}]

const serviciotextfra=[{
    titulo:"Services",
    texto:"Nous sommes un groupe de professionnels de la santé dentaire concentrés sur une seule mission : le bien-être et la santé bucco-dentaire de nos patients, en leur offrant une expérience unique à chaque visite." 
    +"\n <strong>Klin</strong> axe ses diagnostics et ses traitements en prenant comme aspects fondamentaux les conditions biologiques, structurelles, fonctionnelles, esthétiques et émotionnelles de nos patients, en cherchant toujours à améliorer leur qualité de vie par l'éducation et la prévention de la santé bucco-dentaire à travers un soin personnalisé qui prend toujours comme prémisse un service avec la plus haute qualité et innovation technologique."
    }]

export {serviciotextfra,serviciosfra,contactofra,equipofra,footerfra,tecnologiasfra,headerfra,doctoresfra,turismofra,agendafra,conocefra,regresarfra};