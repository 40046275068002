import React, { useState,useEffect } from 'react';
import './Agenda.css'
import { AppointmentPicker } from "react-appointment-picker";
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'
import {agenda} from '../Datos/Datos';
import {agendaing} from '../Datos/Datosing';
import {agendafra} from '../Datos/Datosfra';

function Agenda({idioma}) {
    const [appointments, setAppointments] = useState([]);
    const [currentValue, setcurrentValue] = useState(new Date());
    const [disabledDates, setdisabledDates] = useState(null);
    const [firstDay, setfirstDay] = useState(0);
    const [info, setinfo] = useState(agenda)

    useEffect(() => {
      if(idioma==="esp"){
        setinfo(agenda[0]);
      }
      if(idioma==="ing"){
        setinfo(agendaing[0])
      }
  
      if(idioma==="fra"){
        setinfo(agendafra[0])
      }
    }, [idioma])


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const appointment = {
      mensaje: "Hola! Me interesa un cita en Klin." +
      "%0aNombre: " + form.elements.fname.value + ' ' + form.elements.lname.value +
      "%0aCorreo: " + form.elements.email.value +
      "%0aFecha Cita: " + form.elements.date.value +
      "%0aRango de horario: " + form.elements.horario.value +
      "%0aMotivo Consulta:" + form.elements.notas.value
    };
    setAppointments([appointment]);
    
    const win = window.open(`https://wa.me/5648262614?text=${appointments[0].mensaje}`, "_blank");
    win.focus();
    var frm = document.getElementsByName('contact-form')[0];
   frm.reset();  // Reset all form data
    setAppointments([])
  }
  const today = new Date()
  let tomorrow =  new Date()
tomorrow.setDate(today.getDate() + 1)
const yyyy = tomorrow.getFullYear();
let mm = tomorrow.getMonth() + 1; // Months start at 0!
let dd = tomorrow.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = yyyy + '-' + mm + '-' + dd;


  const onCurrentValueChanged = (e) => {
    setcurrentValue(e.value)
  }

  return (
    <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="ay-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
          <div className="row align-items-center contenido-titulo">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <h1 className="mb-4 display-3 fw-bold blanco">Agenda</h1>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-1">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 

              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
              <h4>{info.titulo}</h4>
                <p class="klin-preview">{info.subtitulo}</p>
              </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
              <div className='agenda'>
              <form className="pay-8" name="contact-form" onSubmit={handleSubmit}>
              <span className='info-detalle'>{info.detalle}</span>

<div className='container-form'>
<div className="info-agenda">
<div className="entrada">
<label for="fname">{info.nombre}</label>
<input type="text" id="fname" name="firstname" required placeholder={info.nombredesc}/>
</div>
<div className="entrada">
<label for="lname">{info.apellido}</label>
<input type="text" id="lname" name="lastname" required placeholder={info.apellidodesc}/>
</div>
<div className="entrada">
<label for="lname">{info.correo}</label>
<input type="email" id="email" name="email" required placeholder={info.correodesc}></input>
</div>
<div className="entrada">
<label for="lname">{info.telefono}</label>
<input type="tel" id="tel" name="tel" required placeholder={info.telefonodesc}></input>
</div>
<div className="entrada">
<label for="lname">{info.fecha}</label>
<input type="date" id="date" name="date" required placeholder={info.fechadesc} defaultValue={formattedToday}
       min={formattedToday}></input>
</div>
<div className="entrada">
<label for="pet-select">{info.horario}<div class="tooltip">Hover over me
  <span class="tooltiptext">Tooltip text</span>
</div></label>

<select name="horario" required id="horario">
    <option value="">{info.selecciona}</option>
    <option value="07:00-10:00">07:00-10:00</option>
    <option value="08:00-11:00">08:00-11:00</option>
    <option value="09:00-12:00">09:00-12:00</option>
    <option value="10:00-13:00">10:00-13:00</option>
    <option value="13:00-16:00">13:00-16:00</option>
    <option value="16:00-20:00">16:00-20:00</option>
</select>
</div>   
</div>    
<div className='info-notas'>
<label>
{info.notas}
    </label>
    <textarea id="notas" name="notes"></textarea>
  </div>
  <button type="submit" className="form-boton">{info.agendar}</button>
  </div>
</form>
<img className="libera" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div>
            </div>
          </div>
          </section>
          </main>
  )
}
export default Agenda


