import React,{useState,useEffect} from 'react'
import klinlogo from './klins.webp'
import { Link, NavLink } from 'react-router-dom'
import './Contact.css'
import {contacto} from '../Datos/Datos';
import {contactoing} from '../Datos/Datosing';
import {contactofra} from '../Datos/Datosfra';

function Contact({idioma}) {
  const [info, setinfo] = useState(contacto[0])
  useEffect(() => {
    if(idioma==="esp"){
      setinfo(contacto[0]);
    }
    if(idioma==="ing"){
      setinfo(contactoing[0])
    }

    if(idioma==="fra"){
      setinfo(contactofra[0])
    }
  }, [idioma])
  return (
    <div className="Contact" style={{paddingTop:"1rem"}}>
    <div className="rightcolumn" style={{zIndex:"1"}}>
    <div className="card" style={{paddingTop:"0px",marginTop:"0px"}}>
      <div className="fakeimg" style={{paddingBottom:"0px"}}><img src={klinlogo} width="150" alt="" /></div>
      <div>
      <p className='contact-info'><strong>Luz Saviñon 13,</strong> Col del Valle Centro, Benito Juárez, 03100 Ciudad de México, CDMX</p>
      <p  className='contact-datos'>+52 56 4826 2614 (Whatsapp)</p>
      <p  className='contact-datos'>goaklin@gmail.com</p>
      <h2  className='contact-big'>{info.semana}</h2>
      <p  className='contact-datos' style={{fontSize:"14px"}}>7:00am-8:00pm</p>
      </div>
    </div>
    {/* <div className="card">
      <h4>KLIN TALKS</h4>
      <div className="fakeimg">Image</div><br/>
      <div className="fakeimg">Image</div><br/>
      <div className="fakeimg">Image</div>
    </div> */}
  </div>
            <form className="py-2" style={{zIndex:"1"}}>
    <h2 class="fw-bold">{info.titulo}</h2>
    <br/>
    <div className='container-form'>
    <div className="info-agenda">
    <div className="entrada">
    <label for="fname">{info.nombre}</label>
    <input type="text" id="fname" name="firstname" placeholder={info.nombredesc}/>
    </div>
    <div className="entrada">
    <label for="lname">{info.apellido}</label>
    <input type="text" id="lname" name="lastname" placeholder={info.apellidodesc}/>
    </div>
    <div className="entrada">
    <label for="lname">{info.correo}</label>
    <input type="email" id="email" name="email" placeholder={info.correodesc}></input>
    </div>
    <div className="entrada">
    <label for="lname">{info.telefono}</label>
    <input type="tel" id="tel" name="tel" placeholder={info.correodesc}></input>
    </div>
    </div>    
    <div className='info-notas'>
    <label>
    {info.notas}
        </label>
        <textarea name="notes"></textarea>
      </div>
      <button type="submit" className="form-boton">{info.enviar}</button>
      </div>
    </form>
    <div className="rightcolumn py-8" style={{zIndex:"1",backgroundColor:"transparent"}}>
    <div className="card"  style={{backgroundColor:"transparent"}}>
      <p>{info.liberatitulo}</p>
      <p>{info.estasclick}</p>
      <h4>{info.cita}</h4>
    </div>
      <Link to="/agenda"><button type="submit" style={{backgroundColor: "#89D1DD"}} className="form-boton">{info.agendar}</button></Link>
      <div className='info'><a href='https://www.facebook.com/klinbygoa' target="_blank"><i href='https://www.facebook.com/klinbygoa'  className="fa-brands fa-2x fa-facebook"></i></a><a href='https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=' target="_blank"><i className="fa-brands fa-2x fa-instagram"></i></a><a href='https://wa.me/5648262614' target="_blank"><i className="fa-brands fa-2x fa-whatsapp"></i></a></div> 

    {/* <div className="card">
      <h4>KLIN TALKS</h4>
      <div className="fakeimg">Image</div><br/>
      <div className="fakeimg">Image</div><br/>
      <div className="fakeimg">Image</div>
    </div> */}
  </div>
  </div>
  )
}

export default Contact