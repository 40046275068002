import React, { useState } from 'react';
import './App.css';
import Inicio from './Home/Inicio';
import Header from './Components/Nav/Header';
import BlogList from './KlinTalks/KlinTalks';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Servicios from './Servicios/Servicios';
import Footer from './Components/Nav/Footer';
import Agenda from './AgendaCita/Agenda';
import DetalleServicios from './Servicios/DetalleServicios';
import NotFound from './NotFound/NotFound';
import { IconButton } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import SideMenu from './Components/Nav/SideMenu';
import Team from './Team/Team';
import Tecnologies from './Tecnologías/Tecnologies';
import Klintalks from './KlinTalks/KlinTalks'
import Turismo from './Turismo/Turismo'

import { useEffect } from "react";
import { initializeTagManager } from "./Gtm/Gtm";

function App() {
  const [blogid, setblogid] = useState(0);
  const [idioma, setidioma] = useState("esp")
  const [detalle, setdetalle] = useState(0)

  useEffect(() => {
    initializeTagManager();
  }, []);
  
  return (
    <Router>
    <div>
      <div>
       <Header idioma={idioma} setidioma={setidioma}/>
      </div>
      <SideMenu/>
      <Routes>
        <Route path="/" element={<Inicio idioma={idioma} setdetalle={setdetalle}/> } />
        <Route path="/blog" element={<BlogList idioma={idioma}/> } />
        <Route path="/team" element={<Team  idioma={idioma}/> } />
        <Route path="/servicios" element={<Servicios blogid={blogid} setblogid={setblogid} idioma={idioma} setdetalle={setdetalle}/> }/>
        <Route path="/tecnologies" element={<Tecnologies idioma={idioma}/> }/>
        <Route path="/agenda" element={<Agenda idioma={idioma}/> } />
        <Route path="/klintalks" element={<Klintalks idioma={idioma}/> } />
        <Route path="/turismo" element={<Turismo idioma={idioma}/> } />
        <Route path="*" element={<NotFound idioma={idioma}/> } />
        <Route path="/servicios/:id" element={<DetalleServicios blogid={blogid} idioma={idioma} detalle={detalle} setblogid={setblogid}/> } />
      </Routes>
      <Footer idioma={idioma}/>
    </div>
  </Router>
  );
}

export default App;
