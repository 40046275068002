const serviciosing =[
    {id:0,
    titulo: "DENTAL PROFESSIONAL PROPHYLAXIS",
    imagen:"assets/images/Servicios/limpieza.jpg",
    sinopsis:"Fast, painless and without damaging your teeth. ",
    desplegable:"In KLIN by GOA we carry out dental cleaning with the air-flow prophylaxis master technology, a device manufactured in Switzerland. It offers a unique solution for the prevention of caries and periodontal disease ",
    descripcion:"Thanks to the expulsion of nano particles of erythritol and specialized tips, it is possible to access spaces that until now, seemed impossible to reach; achieving a high efficiency cleaning in a short time. However, it is important to note that the advantages of air-flow are not only limited to its effectiveness, but also to the comfort referred by patients, compared to other conventional methods, which significantly reduces the working time and the painful sensation that could be experienced with this type of intervention. Simply a new way to prevent and control oral hygiene!"
    ,galeria:[{original:"/assets/images/Galeria-servicios/limpieza-1.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-1.webp"},{original:"/assets/images/Galeria-servicios/limpieza-2.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-2.webp"},{original:"/assets/images/Galeria-servicios/limpieza-3.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-3.webp"}]
},
    {
    id:1,
    titulo: "MAINTENANCE",
    sinopsis:"Semi-annual and annual",
    imagen:"assets/images/Servicios/mantenimiento.jpg",
    desplegable:"In KLIN by GOA we have a preventive and conservative approach",
    descripcion:"We know that every restoration change involves, in most cases, greater wear and tear of the dental structure and that is why we create an annual maintenance protocol that seeks to preserve long-lived restorations in the mouth. The premise is to re-seal the interface between the restoration and the tooth to avoid a replacement of the same in the short or medium term. Our maintenance protocol consists of:"
    + "Radiographic control of all restorations and teeth present in the mouth"
    + "\\n▪ complete clinical analysis"
    + "\\n▪ Professional dental cleaning with 'air-flow prophylaxis master' system "
    + "\\n▪ Maintenance of all restorations present in the mouth, avoiding replacement in the Medium term"
    + "\\n▪ Topical application of fluoride and application of recalcifying treatments"
    + "\\n▪ Performing a nocturnal occlusal guard ",
    galeria:[{original:"/assets/images/Galeria-servicios/mantenimiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-1.webp",},{original:"/assets/images/Galeria-servicios/mantenimiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-2.webp"},{original:"/assets/images/Galeria-servicios/mantenimiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-3.webp"}],
    },
    {
    id:2,
    titulo: "IN-OFFICE BLEACHING",
    sinopsis:"Lightens up to shades in 45 minutes ",
    imagen:"assets/images/Servicios/BLANQUEAMIENTO.jpg",
    desplegable:" Philips Zoom Whitespeed lamp is the number 1 brand of whitening requested by patients in the united states and is clinically proven to bleaching teeth up to eight shades in 45 minutes offering results and efficacy clinically superior to others professional whitening alternatives. That’s why at klin by goa we have this technology to give you better service every day.",
    descripcion:"Whitespeed obtained a 50% advantage over conventional methods that do not use light immediately after treatment. Philips zoom teeth whitening is usually indicated in patients who do not agree with the color of their teeth. In addition, its main indications are those cases where there is a tooth discoloration, caused by aging, mild dental fluorosis, imperfect dentinogenesis or stains/pigments due to external factors such as coffee, wine and cigar. Before starting teeth whitening, some basic preliminary considerations must be taken into account in order to perform a good treatment. First a detailed clinical history must be made, with the reason for consultation, medical considerations and an evaluation of the habits of each patient. Afterwards, oral and complementary examinations are performed. It is also important to take records, such as colorimetry to specify the color before treatment. Finally, before carrying out the treatment plan, a diagnosis of certainty and establishing a prognosis is basic. It is very important to emphasize that the success of tooth whitening and its longevity in any procedure depends on the habits the patient has. ",
    galeria:[{original:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",},{original:"/assets/images/Galeria-servicios/blanqueamiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-2.webp"},{original:"/assets/images/Galeria-servicios/blanqueamiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-3.webp"}],
},
    

    {
    id:3,
    titulo: "INVISALIGN",
    sinopsis:"Fast, comfortable and more hygienic invisible orthodontics.",
    imagen:"assets/images/Servicios/INVISALIGN.jpg",
    desplegable:"It is the perfect and comfortable solution to correct smile problems, allowing the correct alignment of the teeth in less time than traditional orthodontics.",
    descripcion:"It consists of making transparent plastic splints that are easy to place"
    +"Its placement is very simple, so it allows the patient to place and remove them without dentist's supervision"
    +"The aligners kit is provided to the patient from the start of treatment, having to replace the splint in use every two or three weeks."
    +" \n As they are removable, you can take them off to bite and chew without worrying about breaking or detaching them from your teeth while you eat."
    +"In addition, they are imperceptible and cut at gum level, making them invisible."
    +" An effective solution to achieve the smile of your dreams in the shortest possible time"
    ,    galeria:[{original:"/assets/images/Galeria-servicios/invisalign-1.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-1.webp",},{original:"/assets/images/Galeria-servicios/invisalign-2.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-2.webp"},{original:"/assets/images/Galeria-servicios/invisalign-3.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-3.webp"}],
},
    {
    id:4,
    titulo: "KLIN KIDS",
    sinopsis:"Going to the dentist was never so fun!",
    imagen:"assets/images/Servicios/KLINKIDS.jpg",
    desplegable:"Our main goal is to provide to children appropriate oral care, based on two basic principles: healing and prevention.",
    descripcion:"We are experts in behavior management, minimally invasive dental prevention and preventive orthodontics"
    +"At KLIN Kids we are ready to provide care to children with previous negative experiences, first visits, or with special abilities, using top-level techniques and technology, causing as little pain as possible, reducing discomfort and stress that a visit to the dentist can cause your little ones"
    +"We believe that prevention is the key, so we also give advice and support to parents so that health and smiles start at home"
    +"Together we make a team!",
    galeria:[{original:"/assets/images/Galeria-servicios/kids-1.webp",thumbnail:"/assets/images/Galeria-servicios/kids-1.webp",},{original:"/assets/images/Galeria-servicios/kids-2.webp",thumbnail:"/assets/images/Galeria-servicios/kids-2.webp"},{original:"/assets/images/Galeria-servicios/kids-3.webp",thumbnail:"/assets/images/Galeria-servicios/kids-3.webp"}],
    },
    {
    id:5,
    titulo: "SALIVA CHECK TEST",
    sinopsis:"Clinical test to assess the quality of saliva",
    imagen:"assets/images/Servicios/testsaliva.jpg",
    desplegable:"Saliva-check buffer checks hydration, consistency, ph of saliva at rest, stimulated saliva flow, stimulated saliva ph and damping capacity. These six tests are performed in less than 10 minutes in total per patient.",
    descripcion:"Saliva-check buffer is very useful in identifying contributing factors, such as stress, tobacco, diseases, a pathology of the salivary glands, chronic renal failure, a hormonal imbalance due to menopause and side effects of drugs. The results can be explained to the patient during the conversation about prevention and treatment."
    ,galeria:[{original:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",},{original:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp"},{original:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp"}],

},
         {
    id:6,
    titulo: "LASER TECHNOLOGY",
    sinopsis:"Waterlasse MDX® Our secret weapon against pain.",
    imagen:"assets/images/Servicios/LÁSER.jpg",
    desplegable:"We believe that a dental office appointment does not have to be a negative experience and we use laser technology to reduce pain levels in our patients.",
    descripcion:"WE WANT OUR PATIENTS TO TRUST THAT THEY ARE IN THE BEST HANDS AND THAT WE TAKE CARE OF THEIR COMFORT AND WELLNESS"
    +"\\n"
    +"\\nHOW DO WE DO THAT"
    +"\\nWITH EQUIPMENT LIKE THE WATERLASE MDX, WHICH ENSURES THAT A DENTAL EXPERIENCE IS MINIMALLY INVASIVE, YET PRECISE AND EXCEPTIONALLY PLEASANT. THESE INSTRUMENTS ARE USED BY RENOWNED DENTISTS ALL OVER THE WORLD AND, OF COURSE, IN KLIN BY GOA. THIS IS A GUARANTEE FOR OUR PATIENTS, BECAUSE THEY PROVIDE SIGNIFICANT BENEFITS"
    +"\\n"
    +"\\nIT IS PERFECT FOR CHILD TREATMENTS, BECAUSE IT IS FRIENDLY AND QUIET. THIS WILL KEEP OUR CHILDREN CALM AND PREVENT TRAUMATIC EXPERIENCES WITH DENTAL VISITS."
    +"\\nMINIMIZES WAITING TIME FOR PATIENTS. THIS WAY YOU CAN BETTER MANAGE YOUR ACTIVITIES ON THE DAY OF THE CONSULTATION, AS THE TIME SPENT IN THE DENTAL CHAIR IS SIGNIFICANTLY REDUCED."
    +"\\nREDUCES PAIN LEVELS CONSIDERABLY. THIS IS VERY IMPORTANT FOR PEOPLE SUFFERING FROM ANXIETY BECAUSE IT HELPS TO KEEP THE PATIENT CALM AND REDUCES THE STRESS GENERATED BY TREATMENT."
    +"\\n IT PRODUCES COMFORT BY UNIQUELY IMPROVING DENTAL EXPERIENCES.... YOU WILL DEFINITELY WANT TO COME BACK WITH US!"
    +"\\n IT ELIMINATES OR REDUCES DENTAL SENSITIVITY, AND IN MANY CASES IT IS NOT EVEN NECESSARY TO USE ANESTHESIA."
    +"\\n"
    +"\\nTREATMENTS THAT CAN BE PERFORMED WITH SURGICAL LASER ARE:"
    +"\\n• GINGIVAL CONTOUR IN SMILE DESIGNS WITHOUT ANESTHESIA"
    +"\\n• CARIES REMOVAL WITHOUT ANESTHESIA"
    +"\\n• ELIMINATION OF MELANOSIS (MELANIN CONCENTRATIONS IN THE GUMS) IN THE AESTHETIC AREA OR BIOPSIES"
    +"\\n• OBTAINING MAXIMUM DENTAL ADHESION WHEN PERFORMING AESTHETIC TREATMENTS BASED ON RESIN"
    +"\\n• INFLAMMATION OF THE GUMS DURING DENTAL CLEANING"
    +"\\n• PERIODONTAL TREATMENTS WITH MINIMAL DISCOMFORT",
    galeria:[{original:"/assets/images/Galeria-servicios/Waterlase-1.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-1.webp",},{original:"/assets/images/Galeria-servicios/Waterlase-2.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-2.webp"},{original:"/assets/images/Galeria-servicios/Waterlase-3.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-3.webp"}],

    },
]

const contactoing=[{
    titulo:"Any Questions?",
    nombre:"Name",
    nombredesc:"Your first name",
    apellido:"Last Name",
    apellidodesc:"Your last name",
    correo:"Email",
    correodesc:"Your email",
    telefono:"Phone",
    telefonodesc:"Your cellphone",
    notas:"Reason for appointment",
    enviar:"Send",
    liberatitulo:"Free your smile!",
    estasclick:"You’re one click away from your dream smile. Send us your questions or Schedule an appointment",
    cita:"Make an appointment",
    agendar:"Appointment",
    semana:"MONDAY TO SATURDAY"
   }]

   const regresaring =[{
    regresar:"Get back"
}]

   const agendaing=[{
    titulo:"Free your smile!",
    subtitulo:"You’re one click away from your dream smile. Send us your questions or Schedule an appointment",
 nombre:"Name",
 nombredesc:"Your first name..",
 apellido:"Last Name",
 apellidodesc:"Your last name..",
 correo:"Email",
 correodesc:"Your email..",
 telefono:"Phone number",
 telefonodesc:"Your phone number..",
 fecha:"Date",
 fechadesc:"Date for the appointment",
 horario:"Hours range",
 horariodes:"Choose a range of hours in which you would like your consultation to be made. Take aknowledge that the minimum duration is 1:30 hrs. Your appointment will be confirmed via WhatsApp with the exact time of service.",
 notas:"Reason for appointment",
 enviar:"Send",
 agendar:"Agendar Cita",
 selecciona:"--Select an option--",
 detalle:"*Choose a range of hours in which you would like your consultation to be made. Take aknowledge that the minimum duration is 1:30 hrs."
 +"Your appointment will be confirmed via WhatsApp with the exact time of service."
}]

   const equipoing=[{
    titulo:"Team",
    texto:"<strong>KLIN</strong> by GOA is made up of a group of dentists, highly qualified and committed to offering you a quality service with preventive and aesthetic treatments, and corrective alternatives to various ailments with the use of the highest quality technology that guarantees total satisfaction" 
    +"\n In <strong>KLIN</strong> by GOA We care to listen to our patients and we want to make every visit special"
    +" as our patient, you will have the assurance that you are in the best hands",
}]

const doctoresing =[
    {id:0,
    imagen:"assets/CV/Karina.jpg",
    nombre:"Karina López-Gazcón",
    especialidad: "Dentista",
    descripcion: "Oral and Implant Prosthetics",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Karina-eng.pdf",

},
{id:0,
    imagen:"assets/CV/Guadalupe.jpg",
    nombre:"Guadalupe González",
    especialidad: "Dentista",
    descripcion: "Microscopic Endodontics",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Guadalupe-eng.pdf",

},
{id:0,
    imagen:"assets/CV/Rocio.jpg",
    nombre:"Rocío Vega ",
    especialidad: "Dentista",
    descripcion: "Microscopic Endodontics",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Rocio-eng.pdf",

},
{id:0,
    imagen:"assets/CV/Maria.jpg",
    nombre:"María Zamudio",
    especialidad: "Dentista",
    descripcion: "Pediatric Dentistry",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Maria-eng.pdf",

},
// {id:0,
//     imagen:"assets/CV/Ivonne.jpg",
//     nombre:"Ivonne González",
//     especialidad: "Periodoncista",
//     descripcion: "Periodoncista",
//     facebook:"https://www.facebook.com/klinbygoa",
//     instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
//     twitter:"https://twitter.com",
//     cv:"Ivonne.pdf",

// },
{id:0,
    imagen:"assets/CV/Cesar.jpg",
    nombre:"César Benítez",
    especialidad: "Dentista",
    descripcion: "Orthodontics and Maxillary Orthopedics",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Cesar-eng.pdf",

},
// {id:0,
//     imagen:"assets/CV/Victor.jpg",
//     nombre:"Victor Gallardo ",
//     especialidad: "Dentista",
//     descripcion: "Cirugía Oral y Maxilofacial",
//     facebook:"https://www.facebook.com/klinbygoa",
//     instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
//     twitter:"https://twitter.com",
//     cv:"Victor-eng.pdf",

// },
{id:0,
    imagen:"assets/CV/Lucía.jpg",
    nombre:"Lucía Márquez",
    especialidad: "Dentista",
    descripcion: "Prevention and Whitening",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Lucía-eng.pdf",

},
{id:0,
    imagen:"assets/CV/Elizabeth.jpg",
    nombre:"Elizabeth Pastrana",
    especialidad: "Dentista",
    descripcion: "Prevention and Whitening",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Elizabeth-eng.pdf",

},
{id:0,
    imagen:"assets/CV/Ana.jpg",
    nombre:"Ana Caren González",
    especialidad: "Dentista",
    descripcion: "Prevention and Whitening",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Ana-eng.pdf",

},
]

const tecnologiasing=[{
    titulo:"Technologies",
    subtitulo:"Meet our space!",
    descripcion:"All our treatments and services are provided in comfortable facilities with the highest standards of quality and comfort."
    +"\n In addition, we seek to create an environment that causes harmony between the body and the mind, because we know that the best smile comes from within.",
    nombre:"Diseño de Sonrisa",
    tecnolog:[
    {titulo:"PHILLIPS ZOOM WhiteSpeed®️",imagen:"assets/images/Tecnologias/philips.webp",texto:"The Philips Zoom WhiteSpeed ®️ Lamp is the number 1 whitening brand requested by patients around the world. It is clinically proven to lighten teeth by up to eight shades in 45 minutes, offering clinically superior results and efficacy over other professional whitening alternatives."},
    {titulo:"AirFlow Profilaxis Máster",imagen:"assets/images/Tecnologias/AIRFLOW-MÁQUINA.webp",texto:"Swiss technology widely recommended, for its efficiency and speed, achieving a deep cleaning thanks to its erythrirol-based powder, eliminating biofilm without wearing down the dental surface or restorations."},
    {titulo:"Escaner iTero",imagen:"assets/images/Tecnologias/itero.webp",texto:"With the iTero digital scanner, it's easier to achieve the perfect smile. The mold of the mouth is made without the need to use any type of paste. In a few minutes it will be possible to visualize the entire mouth of the patient on the screen, speeding up diagnoses and allowing the most appropriate treatment to be determined effectively."},
    {titulo:"Waterlasse MDX",imagen:"assets/images/Tecnologias/waterlase.webp",texto:"We use state-of-the-art laser technology to considerably reduce time and pain for our patients, thus guaranteeing minimally invasive, precise and exceptionally pleasant treatments."},
    ]
}]

const turismoing=[{
    descripcion:"Dental tourism has become a new way for people from abroad to attend to their dental needs. One of the biggest advantages and main reasons why people choose dental tourism in Mexico it is because of their prices that are usually much more accessible than in their country of residence."
    +"\n\n At KLIN by GOA we are committed to the well-being of patients by providing them with the best care, as well as support during this dental vacation experience. Our main objective is that you perform your dental treatment while you really enjoy"
    +"everything the City has to offer. Once you have decided to assist you with us, our travel department will contact you to confirm your first appointment and plan together your visit to Mexico City.",
    ofertas:"Get the best Airbnb accommodation deals booking with KLIN",
    quedarse:"Are you coming to CDMX and need a place to stay?" 
    +"\n\n There are hundreds of options for every budget. KLIN by GOA's customer service coordinators will be happy to guide you in your search for the best place for you."
}]

const conoceing=[{
    titulo:"KNOW MORE"
}]

const headering=[{
    inicio:"Home",
    servicios:"Services",
    equipo:"Team",
    tecnologias:"Technologies",
    cita:"Make appointment"
}]

const footering=[{
    enklin: "At KLIN by GOA we provide the best dental service",
    inicio:"Home",
    servicio:"Services",
    equipo:"Team",
    tecnologia:"Technologies",
    agenda:"Make appointment",
    klin:"KLIN Talks",
    Bella:"Embellishment and smile design",
    info:"Information"
}]

const serviciotexting=[{
    titulo:"Services",
    texto:"We are a group of dental health professionals focused on a single mission: the wellness and oral health of our patients, providing a unique experience at each visit."
    +"\n <strong>KLIN</strong> focuses its diagnoses and treatments taking as fundamental aspects the biological, structural, functional, aesthetic and emotional conditions of our patients, always looking to improve their quality of life through education and prevention of oral health through a personalized care that always takes as a premise a service with the highest quality and technological innovation."
}]

export {serviciotexting,serviciosing,contactoing,equipoing,tecnologiasing,headering,footering,doctoresing,turismoing,agendaing,conoceing,regresaring};