import React,{useEffect,useState} from 'react'
import { doctores,equipo,klintalks } from '../Datos/Datos';
import { equipofra } from '../Datos/Datosfra';
import { equipoing } from '../Datos/Datosing';
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'
import { Link } from 'react-router-dom'
import './KlinTalks.css';
import { Player } from 'video-react';
import Entrevista from './Entrevista.webm'

function BlogList({idioma}) {
  const [texto, settexto] = useState(idioma==="esp" ? equipo[0] : idioma==="ing" ? equipoing[0] : equipofra[0])
  useEffect(() => {
    window.scrollTo(0, 0)
    
    if(idioma==="esp"){
      settexto(equipo[0])
    }
    if(idioma==="ing"){
      settexto(equipoing[0])
    }

    if(idioma==="fra"){
      settexto(equipofra[0])
    }
  }, [idioma])


  return (
    <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="tkl-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
            <div className="row align-items-center contenido-titulo">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <div style={{display:"inline-flex"}}><h1 className="mb-4 display-3 fw-bold" style={{color:"#22649A"}}>KLIN</h1><h1 className="mb-4 display-3 fw-bold blanco">Talks!</h1></div>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-2">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white" style={{marginTop: "0px",margin: "0px",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}}>
            <div class="grid-container another">
                <div class="grid-item">
                  {klintalks.map(({titulo,texto,imagen}) => 
                  <div>
                                  <div style={{display:"inline-flex"}}><h1 className="mb-4 display-3 fw-bold" style={{color:"#22649A"}}>{titulo}</h1></div>     
                                  <img className="img-fluid me-2"  style={{maxHeight:"350px",objectFit:"cover"}} src={imagen} alt="" width="100%"/>
                                  <p className='klin-preview' style={{marginBottom: "50px",marginTop:"30px"}}>{texto.slice(0,550).split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}... Ver más</p>           
                                   </div>
                  )}
                </div>
                <div class="grid-item">    <Player
      playsInline
      src={Entrevista}
    /></div>
              </div>
              {/* <div className='members'>
              {doctores.map((doctor) => (
  <div class="col-md-memb col-sm-memb">
      <div class="team-member">
          <div class="team-img">
              <img src={doctor.imagen} alt="team member" class="img-responsive"/>
          </div>
          <div class="team-hover">
              <div class="desk">
                  <p>{doctor.descripcion}</p>
                  <Link to={`/assets/CV/${doctor.cv}`} target="_blank" download><button type="submit" className="form-boton">Descargar CV</button></Link>
              </div>
              <div class="s-link">
                  <a href={doctor.facebook}><i class="fa fa-facebook"></i></a>
                  <a href={doctor.twitter}><i class="fa fa-twitter"></i></a>
                  <a href={doctor.instagram}><i class="fa fa-instagram"></i></a>
              </div>
          </div>
      </div>
      <div class="team-title">
          <h5>{doctor.nombre}</h5>
          <span>{doctor.especialidad}</span>
      </div>
  </div>
  ))}
            <img className="libera" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div> */}
            </div>
            <Contact idioma={idioma}/>

          </div>
          </section>
          </main>
  )
}



export default BlogList