import React, { useEffect,useState } from 'react'
import './Servicios.css'
import { servicios,serviciotextesp } from '../Datos/Datos';
import { serviciosing,serviciotexting } from '../Datos/Datosing';
import { serviciosfra,serviciotextfra } from '../Datos/Datosfra';
import { Link, Outlet } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'

function Servicios({setblogid,idioma,setdetalle}) {
  
  const [descripcion, setdescripcion] = useState(idioma==="esp" ? servicios : idioma==="ing" ? serviciosing : serviciosfra)
  const [texto, settexto] = useState(idioma==="esp" ? serviciotextesp : idioma==="ing" ? serviciotexting : serviciotextfra)
  let navigate = useNavigate(); 
  const routeChange = (id) =>{ 
    setblogid(id)
    setdetalle(id)
    let path = `/servicios/${id}`; 
    navigate(path);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if(idioma==="esp"){
      setdescripcion(servicios);
      settexto(serviciotextesp)
    }
    if(idioma==="ing"){
      setdescripcion(serviciosing);
      settexto(serviciotexting)
    }

    if(idioma==="fra"){
      setdescripcion(serviciosfra);
      settexto(serviciotextfra)
    }
  }, [idioma])
  
  return (
    <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="sy-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
          <div className="row align-items-center contenido-titulo titulo-serv">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <h1 className="mb-4 display-3 fw-bold blanco">{texto[0].titulo}</h1>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-1">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
              <p >{texto[0].texto.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>              </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
              <div className='services'>
              {descripcion.map((servicio) => (
                <div className='servicios-cont'>
              <div onClick={() => routeChange(servicio.id)} style={{cursor: "pointer", backgroundImage: `url(${servicio.imagen})`,backgroundSize:"contain",backgroundSize: "cover",
              backgroundPositionX: "center"}} className="col-sm-9 col-md-4 tarjeta">
                {/* <div key={servicio.id} className="cards h-100 w-100 shadow rounded-lg p-3 p-md-2 p-lg-3 p-xl-5">
                  <div onClick={() => routeChange(servicio.id)} style={{paddingTop: "4vw"}} className="card-body text-center text-md-start">
                    <div className="py-3">
                      <h4 className="fw-bold card-title">{servicio.titulo}</h4>
                      <p className="card-text">{servicio.sinopsis}</p>
                      <button class="btn btn-lg btn-outline-primary rounded-pill" type="submit">Ver más</button>
                    </div>
                  </div>
                </div> */}
                                </div>
                <div className="titulo-prev">
                <h2>{servicio.titulo}</h2>
              </div>
              </div>
              ))}
            </div>
            <img className="libera" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div>
          </div>
          <Contact idioma={idioma}/>
          </section>
          </main>
  )
}

export default Servicios