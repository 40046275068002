import React,{useEffect,useState} from 'react'
import klin from './klinbygo.png'
import {footer} from '../../Datos/Datos';
import {footering} from '../../Datos/Datosing';
import {footerfra} from '../../Datos/Datosfra';

function Footer({idioma}) {

  const [info, setinfo] = useState(footer[0])
  useEffect(() => {
    if(idioma==="esp"){
      setinfo(footer[0]);
    }
    if(idioma==="ing"){
      setinfo(footering[0])
    }

    if(idioma==="fra"){
      setinfo(footerfra[0])
    }
  }, [idioma])

  return (
    <section className="py-6 pt-7 bg-primary-gradient">
    <div className="bg-holder dot1bg"></div>
    
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4 order-0 order-sm-0 pe-6"><a className="text-decoration-none" href="#"><img className="img-fluid me-2" src={klin} alt="" width={70}/><span className="fw-bold fs-1 text-light"><strong>KLIN</strong></span></a>
          <p className="mt-3 text-white">{info.enklin}</p>
        </div>
        <div className="col-4 col-md-4 col-lg mb-3 order-2 order-sm-1">
          <h6 className="lh-lg fw-bold text-light">Menú</h6>
          <ul className="list-unstyled mb-md-4 mb-lg-0">
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/">{info.inicio}</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/servicios">{info.servicio}</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/team">{info.equipo}</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/tecnologies">{info.tecnologia}</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/agenda">{info.agenda}</a></li>

          </ul>
        </div>
        <div className="col-4 col-md-4 col-lg mb-3 order-3 order-sm-2">
          <h6 className="lh-lg fw-bold text-light"> Submenú </h6>
          <ul className="list-unstyled mb-md-4 mb-lg-0">
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="/klintalks">{info.klin}</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" href="">{info.Bella}</a></li>
          </ul>
        </div>
        <div className="col-4 col-md-4 col-lg mb-3 order-1 order-sm-3">
          <h6 className="lh-lg fw-bold text-light">{info.info}</h6>
          <ul className="list-unstyled mb-md-4 mb-lg-0">
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" style={{fontFamily: "'Source Sans Pro', sans-serif"}} href="#!">Luz Saviñon 13, Col del Valle Centro, Benito Juárez, 03100 Ciudad de México, CDMX</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" style={{fontFamily: "'Source Sans Pro', sans-serif"}} href="#!">+52 56 4826 2614 (Whatsapp)</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" style={{fontFamily: "'Source Sans Pro', sans-serif"}} href="#!">goaklin@gmail.com</a></li>
            <li className="lh-lg"><a className="text-light fs--1 text-decoration-none" style={{fontFamily: "'Source Sans Pro', sans-serif"}} href="#!">L-S 7:00am-8:00pm</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  )
}

export default Footer