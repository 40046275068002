
const servicios =[
    {id:0,
    titulo: "LIMPIEZA BUCAL",
    imagen:"assets/images/Servicios/limpieza.jpg",
    sinopsis:"En KLIN BY GOA realizamos las limpiezas dentales con la tecnología Air-Flow Profilaxis Master®",
    desplegable:"En KLIN BY GOA realizamos las limpiezas dentales con la tecnología Air-Flow Profilaxis Master® que es un aparato fabricado en Suiza.",
    descripcion:"Gracias a la expulsión de un spray de nano partículas de eritritol y de unas puntas especializadas, se consigue"
    + "acceder a espacios que hasta ahora, parecían imposibles de alcanzar; logrando una limpieza de alta eficacia en un"
    + "tiempo reducido.Sin embargo, es importante destacar que las ventajas del Air-Flow no sólo se limitan a su eficacia,"
    + "sino a la comodidad referida por los pacientes, frente a otros métodos convencionales, lo cual"
    + "reduce significativamente el tiempo de trabajo y la sensación dolorosa que se pudiese experimentar con este tipo"
    + "de intervenciones."
    + "¡Simplemente una nueva forma de prevención y control de higiene bucal!",
    galeria:[{original:"/assets/images/Galeria-servicios/limpieza-1.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-1.webp"},{original:"/assets/images/Galeria-servicios/limpieza-2.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-2.webp"},{original:"/assets/images/Galeria-servicios/limpieza-3.webp",thumbnail:"/assets/images/Galeria-servicios/limpieza-3.webp"}]
},
    {
    id:1,
    titulo: "MANTENIMIENTO",
    sinopsis:"En KLIN BY GOA tenemos un enfoque preventivo y conservador.",
    imagen:"assets/images/Servicios/mantenimiento.jpg",
    desplegable:"En KLIN BY GOA tenemos un enfoque preventivo y conservador. ",
    descripcion:"Sabemos que cada cambio de restauracióm implica, en la mayoría de los casos, mayor desgaste de estructura dental y por eso creamos un PROTOCOLO DE MANTENIMIENTO ANUAL que busca preservar por más tiempo las restauraciones en boca. "
    + "La premisa es darle mantenimiento a la interfase entre la restauración y el diente para evitar un recambio a corto, mediano y/o largo plazo. El protocolo de mantenimiento consta de: o Control radiográfico de todas las restauraciones y dientes presentes en boca "
    +"\n ° Análisis clínico completo"
    +"\n ° Limpieza Dental con el sistema Air Flow"
    +"\n ° Mantenimiento de todas las restauraciones presentes en boca, evitando el recambio a mediano plazo"
    +"\n ° Aplicación tópica de flúor y/o aplicación de tratamientos recalcificantes"
    +"\n ° Realización de una guarda dental nocturna",
    galeria:[{original:"/assets/images/Galeria-servicios/mantenimiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-1.webp",},{original:"/assets/images/Galeria-servicios/mantenimiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-2.webp"},{original:"/assets/images/Galeria-servicios/mantenimiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/mantenimiento-3.webp"}],
},
    {
    id:2,
    titulo: "BLANQUEAMIENTO",
    sinopsis:"Para brindarle un servicio de primer nivel, realizamos nuestros blanqueamientos con la Lámpara Philips Zoom WhiteSpeed® ",
    imagen:"assets/images/Servicios/BLANQUEAMIENTO.jpg",
    desplegable:" Para brindarle un servicio de primer nivel, realizamos nuestros blanqueamientos con la Lámpara Philips Zoom WhiteSpeed® marca número 1 solicitada por pacientes en Estados Unidos y el mundo. ",
    descripcion:"WhiteSpeed obtuvo en resultados un 50% de ventaja superior a los métodos convencionales que no emplean luz"
    +"inmediatamente después del tratamiento. \n El blanqueamiento dental Philips Zoom suele indicarse en aquellos"
    +"pacientes que no están conformes con el color de sus dientes. Además, sus indicaciones principales son aquellos"
    +"casos donde existe una descoloración dental, causado por el envejecimiento, la fluorosis dental de tipo leve, la"
    +"dentinogénesis imperfecta o tinciones/pigmentos debido a factores externos como café, vino y cigarro.\n Antes de"
    +"empezar el blanqueamiento dental, se tienen que tener en cuenta unas consideraciones previas básicas para poder"
    +"realizar un buen tratamiento.\n Primero se debe realizar una historia clínica detallada, con el motivo de consulta, las"
    +" consideraciones médicas y una evaluación de los hábitos de cada paciente.\n Después, se procede a las exploraciones"
    +"oral y complementaria. También es importante la toma de registros, como la colorimetría para precisar el color"
    +"antes del tratamiento. \n Finalmente, antes de realizar el plan de tratamiento, es básico un diagnóstico de certeza y"
    +"establecer un pronóstico. Es muy importante el destacar que el éxito del blanqueamiento dental y longevidad de"
    +"éste en cualquier procedimiento depende de los hábitos que tenga el paciente.",
    galeria:[{original:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-1.webp",},{original:"/assets/images/Galeria-servicios/blanqueamiento-2.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-2.webp"},{original:"/assets/images/Galeria-servicios/blanqueamiento-3.webp",thumbnail:"/assets/images/Galeria-servicios/blanqueamiento-3.webp"}],
    },
    {
    id:3,
    titulo: "INVISALIGN",
    sinopsis:"Nuestro objetivo principal es darle a los pequeños del hogar un cuidado bucodental apropiado.",
    imagen:"assets/images/Servicios/INVISALIGN.jpg",
    desplegable:"Es la solución perfecta y cómoda para corregir problemas de sonrisa, permitiendo la correcta alineación de los dientes en menos tiempo que la ortodoncia tradicional.",
    descripcion:"Consiste en la realización de férulas de plástico transparente de fácil colocación. "
    +"Su colocación es de lo más sencillo, por lo que permite al panciente colocarlas y retirarlas sin la supervisión de su odontólogo. "
    +"\n El kit de alineadores es facilitado al paciente desde el inicio del tratamiento, teniendo que ir sustituyendo la férula en uso, cada dos o tres semanas."
    +"\n Al ser removibles podrás retirarlos para morder y masticar sin preocupación, sin la preocupación de romperlos o desprenderlos de tus dientes mientras comes."
    +"Además, son imperceptibles y cortados al nivel de la encía, por lo que nadie los notará ni de lejos ni de cerca. Una solución eficaz para conseguir la sonrisa de tus sueños en el menor tiempo posible.",
    galeria:[{original:"/assets/images/Galeria-servicios/invisalign-1.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-1.webp",},{original:"/assets/images/Galeria-servicios/invisalign-2.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-2.webp"},{original:"/assets/images/Galeria-servicios/invisalign-3.webp",thumbnail:"/assets/images/Galeria-servicios/invisalign-3.webp"}],
    },
    {
    id:4,
    titulo: "KLIN KIDS",
    sinopsis:"¡Ir al dentista nunca fue tan divertido!",
    imagen:"assets/images/Servicios/KLINKIDS.jpg",
    desplegable:"Nuestro objetivo principal es darle a los pequeños del hogar un cuidado bucodental apropiado, basándonos en dos principios básicos: la curación y la prevención.",
    descripcion:"Somos expertos en el manejo de conducta, prevención odontológica mínimamente invasiva y ortodoncia preventiva. "
    +"En KLIN Kids estamos listos para brindar atención a niños con experiencias negativas previas, primeras visitas, o con capacidades especiales, utilizando técnicas y tecnología de primer nivel, causando el menor dolor posible, reduciendo el malestar y estrés que una visita al dentista pueda ocasionarles a tus pequeños."
    +"\n Creemos que la prevención es la clave, por lo que además damos asesorías y acompañamiento a los padres de familia para que la salud y las sonrisas comiencen desde casa.¡Juntos hacemos equipo!",
    galeria:[{original:"/assets/images/Galeria-servicios/kids-1.webp",thumbnail:"/assets/images/Galeria-servicios/kids-1.webp",},{original:"/assets/images/Galeria-servicios/kids-2.webp",thumbnail:"/assets/images/Galeria-servicios/kids-2.webp"},{original:"/assets/images/Galeria-servicios/kids-3.webp",thumbnail:"/assets/images/Galeria-servicios/kids-3.webp"}],
    },
    {
    id:5,
    titulo: "SALIVA CHECK TEST",
    sinopsis:"Exámen clinico para evaular la calidad de la saliva",
    imagen:"assets/images/Servicios/testsaliva.jpg",
    desplegable:"La de verificación de saliva verifica la hidratación, la consistencia, el pH de la saliva en reposo, la tasa de flujo de saliva estimulada, el pH de la saliva estimulada y la capacidad de amortiguación. Estas seis pruebas se realizan en menos de 10 minutos en total por paciente.",
    descripcion:"La válvula de control de saliva es muy útil para identificar los factores que contribuyen, como el estrés, el tabaquismo, las enfermedades, la patología de las glándulas salivales, la insuficiencia renal crónica, el desequilibrio hormonal debido a la menopausia y los efectos secundarios de los medicamentos. Los resultados se pueden explicar al paciente durante la conversación sobre prevención y tratamiento.",
    galeria:[{original:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-1.webp",},{original:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-2.webp"},{original:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp",thumbnail:"/assets/images/Galeria-servicios/Test-de-saliva-3.webp"}],
},
    {
    id:6,
    titulo: "TECNOLOGÍA LÁSER",
    sinopsis:"Creemos que una cita al consultorio dental no tiene que ser una experiencia negativa y utilizamos la tecnología LÁSER.",
    imagen:"assets/images/Servicios/LÁSER.jpg",
    desplegable:"Creemos que una cita al consultorio dental no tiene que ser una experiencia negativa y utilizamos la tecnología LÁSER para reducir los niveles de dolor en nuestros pacientes. ",
    descripcion:"Queremos que nuestros pacientes confíen en que se encuentran en las mejores manos y que nos ocupamos de su"
    +"comodidad y bienestar. ¿Cómo lo logramos? Con equipos como el WaterLase MDX, que garantizan que una"
    +"experiencia dental resulte mínimamente invasiva, pero precisa y excepcionalmente agradable. Estos instrumentos son"
    +"utilizados por odontólogos reconocidos en todo el mundo y, por supuesto, en KLIN BY GOA; lo cual es una garantía"
    +"para nuestros pacientes, pues brindan notables beneficios:\n \n \n"
    +" \n \t ▪ Es perfecto para tratamientos infantiles, porque resulta amigable y silencioso. Lo que mantendrá tranquilos a nuestros"
    +"niños y evitará experiencias traumáticas con las visitas al odontólogo."
    +" \n \t ▪ Minimiza el tiempo de espera para los pacientes. Así podrá gestionar mejor sus actividades el día de la consulta, pues"
    +"el tiempo que se pasa en el sillón dental se reduce significativamente."
    +" \n \t ▪ Reduce considerablemente los niveles de dolor. Esto es muy importante para las personas que sufren de ansiedad"
    +"porque ayuda a mantener al paciente tranquilo y reduce el estrés que le genera el tratamiento."
    +" \n \t ▪ Produce comfort mejorando de manera inigualable las experiencias dentales…. ¡sin duda querrá volver con nosotros!"
    +" \n \t ▪ Elimina o reduce la sensibilidad dental, y en muchos casos ni siquiera es necesario utilizar anestesia."
    +" \n \n \n \n \n Los tratamientos que se pueden realizar con LASER QUIRÚRGICO son:"
    +" \n \t ° Contorno gingival en los diseños de sonrisa sin anestesia"
    +" \n \t ° Eliminación de caries sin anestesia"
    +" \n \t ° Eliminación de melosis racial (concentraciones de melanina en la encía) en zona estética"
    +" \n \t ° Biopsias"
    +" \n \t ° Obtención de máxima adhesión dental cuando se realizar tratamientos estéticos a base de resina"
    +" \n \t ° Desinflamación de encías durante la limpieza dental"
    +" \n \t ° Tratamientos periodontales con mínima molestia",
    galeria:[{original:"/assets/images/Galeria-servicios/Waterlase-1.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-1.webp",},{original:"/assets/images/Galeria-servicios/Waterlase-2.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-2.webp"},{original:"/assets/images/Galeria-servicios/Waterlase-3.webp",thumbnail:"/assets/images/Galeria-servicios/Waterlase-3.webp"}],
    },
]

const regresar =[{
    regresar:"Regresar"
}]

const doctores =[
    {id:0,
    imagen:"assets/CV/Karina.jpg",
    nombre:"Karina López-Gazcón",
    especialidad: "Dentista",
    descripcion: "Prótesis Bucal Y Prótesis Sobre Implante",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Karina.pdf",

},
{id:0,
    imagen:"assets/CV/Guadalupe.jpg",
    nombre:"Guadalupe González",
    especialidad: "Dentista",
    descripcion: "Endodoncia Microscópica",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Guadalupe.pdf",

},
{id:0,
    imagen:"assets/CV/Rocio.jpg",
    nombre:"Rocío Vega ",
    especialidad: "Dentista",
    descripcion: "Odontología Restauradora Avanzada",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Rocio.pdf",

},
{id:0,
    imagen:"assets/CV/Maria.jpg",
    nombre:"María Zamudio",
    especialidad: "Dentista",
    descripcion: "Odontopediatría",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Maria.pdf",

},
// {id:0,
//     imagen:"assets/CV/Ivonne.jpg",
//     nombre:"Ivonne González",
//     especialidad: "Periodoncista",
//     descripcion: "Periodoncista",
//     facebook:"https://www.facebook.com/klinbygoa",
//     instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
//     twitter:"https://twitter.com",
//     cv:"Ivonne.pdf",

// },
{id:0,
    imagen:"assets/CV/Cesar.jpg",
    nombre:"César Benítez",
    especialidad: "Dentista",
    descripcion: "Ortodoncia y Ortopedia Maxilar",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Cesar.pdf",

},
{id:0,
    imagen:"assets/CV/Victor.jpg",
    nombre:"Victor Gallardo ",
    especialidad: "Dentista",
    descripcion: "Cirugía Oral y Maxilofacial",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Victor.pdf",

},
{id:0,
    imagen:"assets/CV/Lucía.jpg",
    nombre:"Lucía Márquez",
    especialidad: "Dentista",
    descripcion: "Prevención y Blanqueamiento",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Lucía.pdf",

},
{id:0,
    imagen:"assets/CV/Elizabeth.jpg",
    nombre:"Elizabeth Pastrana",
    especialidad: "Dentista",
    descripcion: "Prevención y Blanqueamiento",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Elizabeth.pdf",

},
{id:0,
    imagen:"assets/CV/Ana.jpg",
    nombre:"Ana Caren González",
    especialidad: "Dentista",
    descripcion: "Prevención y Blanqueamiento",
    facebook:"https://www.facebook.com/klinbygoa",
    instagram:"https://instagram.com/klinbygoa?igshid=NTdlMDg3MTY=",
    twitter:"https://twitter.com",
    cv:"Ana.pdf",

},
]

const footer=[{
    enklin: "En KLIN by GOA damos el mejor servicio odontológico",
    inicio:"Inicio",
    servicio:"Servicios",
    equipo:"Equipo",
    tecnologia:"Tecnologías",
    agenda:"Agenda tu Cita",
    klin:"KLIN Talks",
    Bella:"Enbellecimiento y diseño de sonrisa",
    info:"Información"
}]

const serviciotextesp=[{
    titulo: "Servicios",
    texto:"Somos un grupo de profesionales de la salud dental enfocados en una sola misión: la salud y el bienestar bucodental de"
    +" nuestros pacientes, brindando una experiencia única en cada visita."
    +"\n\n <strong>KLIN</strong> enfoca sus diagnósticos y tratamientos tomando como aspectos fundamentales las condiciones biológicas,"
    +"estructurales, funcionales, estéticas y emocionales de nuestros pacientes, siempre buscando mejorar su calidad de"
    +" vida por medio de la educación y la prevención de salud bucodental mediante una atención personalizada que tome"
    +" siempre como premisa un servicio con la más alta calidad e innovación tecnológica."
}]


const tecnologias=[{
    titulo:"Tecnologías",
    subtitulo:"Conoce nuestro espacio!",
    descripcion:"Todos nuestros tratamientos y servicios se brindan en cómodas instalaciones con los más altos estándares de calidad y comodidad."
    +"\n\n Además, buscamos crear un ambiente que provoque armonía entre el cuerpo y la mente, porque sabemos que la mejor sonrisa viene desde adentro.",
    nombre:"Diseño de Sonrisa",
    tecnolog:[
    {titulo:"PHILLIPS ZOOM WhiteSpeed®️",imagen:"assets/images/Tecnologias/philips.webp",texto:"La Lámpara Philips Zoom WhiteSpeed®️es la marca número 1 de blanqueamiento solicitada por los pacientes alrededor del mundo. Está clínicamente probada para aclarar los dientes hasta ocho tonos en 45 minutos ofreciendo resultados y eficacia clínicamente superiores frente a otras alternativas de blanqueamiento profesional. "},
    {titulo:"AirFlow Profilaxis Máster",imagen:"assets/images/Tecnologias/AIRFLOW-MÁQUINA.webp",texto:"Tecnología suiza ampliamente recomendada, por su eficacia y rapidez, logrando una limpieza profunda gracias a su polvo hecho a base de eritrirol, eliminando el biofilm sin desgastar la superficie dental ni las restauraciones."},
    {titulo:"Escaner iTero",imagen:"assets/images/Tecnologias/itero.webp",texto:"Con el escaner digital iTero, es más fácil llegar a la sonrisa perfecta. Se realiza el molde de la boca sin necesidad de utilizar ningún tipo de pasta. En pocos minutos será posible visuaizar en pantalla toda la boca del paciente, acelerando diagnósticos y permitiendo determinar de manera eficaz el tratamiento más adecuado."},
    {titulo:"Waterlasse MDX",imagen:"assets/images/Tecnologias/waterlase.webp",texto:"Utilizamos tecnología láser de última generación para reducir considerablemente el tiempo y el dolor en nuestros pacientes, garantizando así tratamientos mínimamente invasivos, precisos y excepcionalmente agradables."},
    ]
}]

const turismo=[{
    descripcion:"El turismo dental se ha convertido en una nueva forma para que las personas del extranjero atiendan sus necesidades dentales. Una de las mayores ventajas y principales razones por las que las personas eligen el turismo dental en México es por sus precios que suelen ser mucho más accesibles que en su país de residencia."
    +" \n\n En KLIN by GOA estamos comprometidos con el bienestar de los pacientes brindándoles la mejor atención, así como apoyo durante esta experiencia de vacaciones dentales. Nuestro principal objetivo es que realices tu tratamiento dental mientras realmente disfrutas"
    +"todo lo que la Ciudad tiene para ofrecer. Una vez que haya decidido atenderlo con nosotros, nuestro departamento de viajes se comunicará con usted para confirmar su primera cita y planificar juntos su visita a la Ciudad de México.",
    ofertas:"Consigue las mejores ofertas de alojamiento de Airbnb reservando con KLIN",
    quedarse:"¿Vienes a la CDMX y necesitas un lugar donde quedarte?"
    +"\n\n Hay cientos de opciones para cada presupuesto. Los coordinadores de servicio al cliente de KLIN by GOA estarán encantados de guiarte en tu búsqueda del mejor lugar para ti." 
}]

const equipo=[{
    titulo:"Equipo",
    texto:"<strong>KLIN</strong> by GOA está conformado por un grupo de odontólogos, altamente calificados y comprometidos en ofrecerte un servicio de calidad con tratamientos preventivos y estéticos," 
    +"así como alternativas correctivas a diversos padecimientos con el uso de tecnología de la más alta calidad que garantiza satisfacción total."
    +"\n En <strong>KLIN</strong> by goa nos importa escuchar a nuestros pacientes y que cada visita sea especial."
    +"Como paciente, usted tendrá la seguridad de que se encuentra en las mejores manos.",
}]

const header=[{
    inicio:"Inicio",
    servicios:"Servicios",
    equipo:"Equipo",
    tecnologias:"Tecnologías",
    cita:"Agenda tu cita"
}]

const contacto=[{
 titulo:"Tienes alguna duda?",
 nombre:"Nombre(s)",
 nombredesc:"Tu nombre",
 apellido:"Apellido(s)",
 apellidodesc:"Tus apellidos",
 correo:"Correo",
 correodesc:"Tu email",
 telefono:"Teléfono",
 telefonodesc:"Tu teléfono",
 notas:"Motivo Consulta",
 enviar:"Enviar",
 liberatitulo:"¡Libera tu sonrisa!",
 estasclick:"Estás a un click de la sonrisa de tus sueños. Envíanos tus dudas o agenda una cita",
 cita:"Agenda tu cita",
 agendar:"Agendar",
 semana:"LUNES A SÁBADO"
}]

const conoce=[{
    titulo:"CONÓCENOS MÁS"
}]

const agenda=[{
    titulo:"¡Libera tu sonrisa!",
    subtitulo:"Estás a un click de la sonrisa de tus sueños. Envíanos tus dudas o agenda una cita",
 nombre:"Nombre(s)",
 nombredesc:"Tu nombre..",
 apellido:"Apellido(s)",
 apellidodesc:"Tus apellidos..",
 correo:"Correo",
 correodesc:"Tu email..",
 telefono:"Teléfono",
 telefonodesc:"Tu teléfono..",
 fecha:"Fecha",
 fechadesc:"Día preferente de cita",
 horario:"Rango de Horario",
 horariodes:"Elige un rango de horarios en el cuál te gustaría que se realizara tu consulta. Toma en cuenta que el tiempo mínimo de duración de consulta es de 1:30 hrs. Tu cita se confirmará vía WhatsApp con la hora exacta de atención.",
 notas:"Motivo Consulta",
 enviar:"Enviar",
 agendar:"Agendar Cita",
 selecciona:"--Selecciona una opción--",
 detalle:"*Elige un rango de horarios en el cuál te gustaría que se realizara tu consulta. Toma en cuenta que el tiempo mínimo de duración de consulta es de 1:30 hrs."
 +"Tu cita se confirmará vía WhatsApp con la hora exacta de atención."
}]

const klintalks=[
    {blogid:0,titulo:"¿QUÉ ES UN IRRIGADOR DENTAL?",imagen:"assets/images/Servicios/LÁSER.jpg",texto:"El irrigador dental es uno de los complementos o herramientas de higiene bucal que sirven para mejorar la limpieza de tus dientes y encías. La higiene bucodental es fundamental para disfrutar de una sonrisa saludable, en la que no existan bacterias dañinas."
+"\n Seguro que sabes perfectamente qué es un irrigador dental porque has debido de verlo en más de una ocasión en la consulta del dentista. Básicamente, se trata de un complemento de limpieza bucal, que funciona gracias a la emisión de un chorro de agua a presión y que permite mejorar la limpieza interdental y de las encías."
+"\n El aparato es muy parecido al que tenemos en la clínica odontológica, pero bastante más sencillo. Aunque el funcionamiento del irrigador dental profesional y el casero es muy similar. La idea principal de esta herramienta de higiene es que te ayude a eliminar con seguridad la placa bacteriana de aquellas zonas a las que no puedes llegar con el cepillo de dientes. Además, el irrigador bucal también ejerce un suave masaje en las encías, logrando activarlas y mejorando su aspecto. Sin duda, en nuestra clínica dental en Toledo recomendamos muchísimo el uso de este complemento en tus rutinas de limpieza bucal. Eso sí, sin olvidarte de usar también del hilo dental, ya que tienen funciones muy distintas"
+"\n Si estás buscando un irrigador dental debes de saber que actualmente hay muchos tipos de irrigadores disponibles. Los hay altamente tecnológicos y otros mucho más sencillos. La elección de uno u otro depende de numerosos factores. Si solo necesitas un aparato de irrigación dental, bastará con que adquieras un irrigador básico."
+"\n Todos los irrigadores dentales que puedes comprar hoy en día incluyen un depósito de agua para poder llevar a cabo su función. En algunos casos, según el fabricante, en este depósito se puede colocar colutorio en lugar de agua. No obstante, esto depende del tipo de irrigador. Nuestro consejo es que sigas siempre las instrucciones del fabricante."
+"\n También hay irrigadores dentales que tienen cabezales diferentes, que puedes cambiar en función de tus necesidades y del tipo de limpieza que necesites llevar a cabo. Una cosa muy importante es que puedas regular la presión del agua, con el fin de mejorar la irrigación. Incluir en los hábitos de limpieza oral un irrigador dental es una idea fabulosa, ya que podrás eliminar mucho mejor la placa dentobacteriana, que se ubica en zonas a las que no puedes llegar con el cepillo de dientes convencional.",
},
    {blogid:1,titulo:"¿CÓMO USAR El IRRIGADOR DENTAL?",imagen:"assets/images/Servicios/limpieza.jpg",texto:"◉ Conéctalo a la fuente de agua: esta puede ser un grifo o, simplemente, llenar el depósito con agua templada."
+"\n ◉ Colocación: coloca la boquilla en tu boca y, empezando por las muelas, sujeta el irrigador separado de los dientes y de las encías. Es mejor que te inclines sobre la pila, para no salpicar agua sobre la encimera cuando lo enciendas."
+"\n ◉ Selecciona la presión y enciéndelo: puede tener un botón de encendido u otra forma de graduación, en función del modelo. Empieza con el nivel más bajo y auméntalo progresivamente. Asegúrate de que la presión es alta, pero que no molesta. Puedes juntar los labios para evitar que el agua salpique, pero asegúrate de que el agua cae en la pila."
+"\n ◉ Sigue la línea de la encía: pasa por todos los dientes y dirige el chorro de agua hacia la línea que dibuja la encía con el diente, y entre todos los espacios interdentales. Recorre la parte superior del diente, la base de la encía y todos los espacios interdentales. Dispara el chorro durante dos segundos sobre cada diente, y repite el proceso tanto en la cara interna como externa de los dientes, hasta haber repasado toda la boca. Deberías tardar unos dos minutos."
+"\n ◉ Vacía el depósito: cuando hayas acabado, apaga el dispositivo, retira la boquilla y vacía el agua sobrante. Si dejas agua en el depósito, podrían proliferar bacterias."
+"\n ◉ Limpia el dispositivo: recuerda aclarar el depósito del irrigador tras cada uso, para garantizar la higiene del mismo."
+"\n\n En KLIN BY GOA contamos con todo lo necesario para proporcionarte una sonrisa perfecta, libre de bacterias y sarro. Someterte a limpiezas dentales profesionales de manera periódica también te ayudará a tener unos dientes bonitos y brillantes.",
}]

export {servicios,doctores,tecnologias,serviciotextesp,contacto,footer,equipo,header,klintalks,turismo,agenda,conoce,regresar};