import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import './Team.css'
import { doctores,equipo } from '../Datos/Datos';
import { equipofra,doctoresfra } from '../Datos/Datosfra';
import { equipoing,doctoresing} from '../Datos/Datosing';
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'

function Team({idioma}) {
  const [texto, settexto] = useState(idioma==="esp" ? equipo[0] : idioma==="ing" ? equipoing[0] : equipofra[0])
  const [doctor, setdoctor] = useState(idioma==="esp" ? doctores : idioma==="ing" ?  doctoresing : doctoresfra )
  const [shown, setShown] = useState(false);
 const [ceve, setceve] = useState(null)
const abrirPop = (valor) =>{
  setceve(`/assets/CV/${valor}#toolbar=0`)
  setShown(true)
}
  useEffect(() => {
    window.scrollTo(0, 0)
    if(idioma==="esp"){
      settexto(equipo[0])
      setdoctor(doctores)
    }
    if(idioma==="ing"){
      settexto(equipoing[0])
      setdoctor(doctoresing)
    }

    if(idioma==="fra"){
      settexto(equipofra[0])
      setdoctor(doctoresfra)
    }
  }, [idioma])

  return (
    <main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="ty-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
            <div className="row align-items-center contenido-titulo">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <h1 className="mb-4 display-3 fw-bold blanco">{texto.titulo}</h1>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-2">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
                <p >{texto.texto.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>
              </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
              <div className='members'>
              {doctor.map((doc) => (
  <div class="col-md-memb col-sm-memb">
      <div class="team-member">
          <div class="team-img">
              <img src={doc.imagen} alt="team member" class="img-responsive"/>
          </div>
          <div class="team-hover">
              <div class="desk" style={{paddingTop:"20%"}}>
                  <p className='docdesc'>{doc.descripcion}</p>
                  <button onClick={() => abrirPop(doc.cv)} type="submit" className="form-boton">Ver CV</button>                 
              </div>
              
              {/* <div class="s-link">
                  <a href={doctor.facebook}><i class="fa fa-facebook"></i></a>
                  <a href={doctor.twitter}><i class="fa fa-twitter"></i></a>
                  <a href={doctor.instagram}><i class="fa fa-instagram"></i></a>
              </div> */}
          </div>
      </div>
      <div class="team-title">
          <h5>{doc.nombre}</h5>
          <span>{doc.descripcion}</span> 
      </div>
  </div>
  ))}
  {shown && ceve &&
  <div className='popup-container'>
        <div class="popup">
          <button onClick={() => setShown(false)} id="close"> &times; </button>
        <iframe src={ceve} title="testPdf" height="700px" width="100%" frameborder="0"/>
        </div>
        </div>}
            <img className="libera" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div>
            </div>
            <Contact idioma={idioma}/>

          </div>
          </section>
          </main>
  )
}



export default Team