import React, { useEffect,useState } from 'react'
import { Link} from 'react-router-dom'
import { servicios,regresar} from '../Datos/Datos';
import { serviciosing,regresaring} from '../Datos/Datosing';
import { serviciosfra,regresarfra} from '../Datos/Datosfra';
import './DetalleServicios.css'
import klin from './klinbygo.png'
import Contact from '../Components/Contact';
import smile from './smile.webp'
import libera from './libera.webp'
import liberaing from './libera-ing.webp'
import liberafra from './libera-fra.webp'
import ImageGallery from 'react-image-gallery';

function DetalleServicios({blogid,idioma,detalle}) {
  const [blog, setblog] = useState(idioma==="esp" ? servicios : idioma==="ing" ? serviciosing: serviciosfra)
  const [first, setfirst] = useState(idioma==="esp" ? regresar : idioma==="ing" ? regresaring: regresarfra)
  const servicio = blog[detalle];
  useEffect(() => {
    window.scrollTo(0, 0)
    if(idioma==="esp"){
      setblog(servicios);
      setfirst(regresar);
    }
    if(idioma==="ing"){
      setblog(serviciosing)
      setfirst(regresaring);
    }

    if(idioma==="fra"){
      setblog(serviciosfra)
      setfirst(regresarfra);
    }
  }, [idioma])
  console.log(regresar)

  return (
<main className="servicios" id="top">
          <div className="bg-holder servicesbg"></div>
    <section className="sy-1">
          <div className="bg-holder dotbg"></div>
          <div className="container position-relative">
          <div className="row align-items-center contenido-titulo titulo-serv">
              <div className="col-md-5 col-lg-6 order-md-1 pt-8"></div>
              <div className="col-md-7 col-lg-12 centro text-md-start pt-5 pt-md-9">
                <h1 className="mb-4 display-3 fw-bold blanco">{servicio.titulo}</h1>
              </div>
            </div>
          </div>
        </section>
    <section style={{paddingBottom:"0px"}}>
    <div className="content-1">
            <div style={{marginTop: "50px",margin: "5vw",maxWidth: "1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "0px"}} className="row justify-content-center white">
            <img className="smile" src={smile}  alt="" /> 
              <div className="col-3 text-center">
              </div>
              <div className="row justify-content-center">
              <div className="col-6 text-center">
                <hr className="w-100 mx-auto blue" style={{height:"2px"}} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-xl-8 text-center">
                <div style={{position:"relative",marginBottom:"2rem",zIndex:"1000"}}>
                <a href="/servicios"><h5>🠤 {first[0].regresar}</h5></a>
                </div>
              <p >{servicio.desplegable.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>  
            </div>
            </div>
            </div>
            <div className="bg-holder dot2ng"></div>
            <div className="row justify-content-center h-100 g-4 white">
              <div  style={{maxWidth:"1000px",marginLeft:"auto",marginRight:"auto",padding: "20px",marginTop:"0px",paddingTop:"0px"}}>
            <div className="row col-sm-10 col-xl-8 text-center" style={{marginLeft:"auto",marginRight:"auto"}}>
              <p >{servicio.descripcion.split('\n').map(line => <p className='klin-preview' dangerouslySetInnerHTML={{__html: line}} style={{marginBottom: "10px"}}></p>)}</p>              </div>
            </div>
            </div>
            <img className="libera lib-det" src={idioma ==="esp" ? libera : idioma ==="ing" ? liberaing : liberafra}  alt="" /> 
            </div>
            <div style={{background:"white"}} className="row justify-content-center">
            <ImageGallery items={servicio.galeria} />
            </div>
          <Contact idioma={idioma}/>
          </section>
          </main>
  )
}

export default DetalleServicios