import React from 'react'
import './SideMenu.css';
import klintalks from './klintalks.png'
import rbnb from './rbnb.png'
import {Link, NavLink } from 'react-router-dom';

function SideMenu() {
  return (
    <div>
    <div className='dot-container'>
    <NavLink to="/klintalks"><div class="dot"><img className="img-fluid me-4 me-md-3 me-lg-4" src={klintalks} width="100%" alt="" /></div></NavLink>
    <NavLink to="/turismo"><div class="dot"><img className="img-fluid me-4 me-md-3 me-lg-4" src={rbnb} width="100%" alt="" /></div></NavLink> 
    {/* <div class="dot"><i class="fa-solid fa-2x fa-tooth"></i></div> */}
    </div>
    <div className='dot-container-right'>
    <a href='https://wa.me/5648262614' target="_blank"><div class="dot-right"><i style={{color: "white"}} class="fa-brands fa-3x fa-whatsapp"></i></div></a>
    </div>
    </div>
  )
}

export default SideMenu